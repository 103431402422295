import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthTokenContext';
import { UserContext } from '../context/UserContext';
import { fetchAssetType, getAssetsOfUser, getEmailOrName } from '../utils/RequestApi';
import { AssetDataEntryType } from '../types/AssetType';
import { RentRow, compileRentData } from './RentAssetView';
import { Asset } from '../types/Asset';
import dayjs from 'dayjs';
import { DataGrid, GridColDef, GridRowsProp, GridToolbarContainer, GridToolbarExport, deDE } from '@mui/x-data-grid';

function LogDataFrame(props: { rentData: RentRow[] }) {
    const { rentData } = props;
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [columns, setColumns] = useState<GridColDef[]>([]);

    function getToolBar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport printOptions={{ disableToolbarButton: true }}
                    csvOptions={{ delimiter: ";" }} />
            </GridToolbarContainer>
        );
    }

    const setupTable = () => {
        const width = 200
        const cellClassName = "log-data-grid-cell"
        const newColumns = [
            { field: "col1", headerName: "Datum-Eingetragen", width: 250, cellClassName: cellClassName },
            { field: "col2", headerName: "User ID", width: 100, cellClassName: cellClassName },
            { field: "col3", headerName: "User", width: width, cellClassName: cellClassName },
            { field: "col4", headerName: "Von", width: width, cellClassName: cellClassName },
            { field: "col5", headerName: "Bis", width: width, cellClassName: cellClassName }]

        const newRows = rentData.map((row, idx) => {
            return { id: idx + 1, col1: row.entryDate.toString(), col2: row.userID, col3: row.userName, col4: row.from, col5: row.to }
        })

        setColumns(newColumns);
        setRows(newRows);
    }

    useEffect(() => {
        setupTable()
    }, [])


    return (
        <div className="log-data-table"
            onClick={e => { e.stopPropagation() }}>
            <DataGrid className="datagrid" rows={rows} columns={columns} slots={{ toolbar: getToolBar, }} localeText={deDE.components.MuiDataGrid.defaultProps.localeText} />
        </div>
    );
}

function RentableTile(props: { asset: Asset, rentData: RentRow[] }) {
    const { asset, rentData } = props;
    const { token } = useContext(AuthContext);
    const [showInfo, setShowInfo] = useState(false);

    const { profileView } = useContext(UserContext)

    const [typeName, setTypeName] = useState(`${asset.type}`);
    const [ownerName, setOwnerName] = useState(`${asset.oid}`);

    const [rentedFrom, setRentedFrom] = useState("");
    const [rentedTo, setRentedTo] = useState("");


    useEffect(() => {
        if (!token) return;
        if (!profileView) return;

        fetchAssetType(asset.type, token).then(aType => {
            if (aType) {
                setTypeName(aType.name);
            } else {
                setTypeName("Lade Fehler")
            }
        })

        getEmailOrName(asset.oid, token).then(name => {
            if (name) {
                setOwnerName(name);
            }
        })
    }, []);

    useEffect(() => {
        const nextRentDataEntry = rentData.find(row => {
            const dateFormat = "DD-MM-YYYY"
            const today = dayjs()
            const from = dayjs(row.from, dateFormat).startOf("day")
            const to = dayjs(row.to, dateFormat).endOf("day")
            if (today.isBetween(from, to)) {
                return true
            }
            return today.isBefore(from)
        })
        if (nextRentDataEntry) {
            setRentedFrom(nextRentDataEntry.from)
            setRentedTo(nextRentDataEntry.to)
        } else {
            setRentedFrom(rentData[rentData.length - 1].from)
            setRentedTo(rentData[rentData.length - 1].to)
        }

    }, [])

    const handleClick = () => {
        setShowInfo(true)
    }

    const closeRentableInfo = () => {
        setShowInfo(false)
    }

    return (
        <div>
            <div className="rentable-tile">
                <div className="rentable-info">
                    <h5>{asset.name}</h5>
                    <table>
                        <tr>
                            <td className="right-align">Element ID:</td>
                            <td className="left-align-pad-left">{asset.aid}</td>
                        </tr>
                        <tr>
                            <td className="right-align">Typ:</td>
                            <td className="left-align-pad-left">{typeName}</td>
                        </tr>
                        <tr>
                            <td className="right-align">Besitzer:</td>
                            <td className="left-align-pad-left">{ownerName}</td>
                        </tr>
                        <tr>
                            <td className="right-align">Gemietet von:</td>
                            <td className="left-align-pad-left">{rentedFrom}</td>
                        </tr>
                        <tr>
                            <td className="right-align">Gemietet bis:</td>
                            <td className="left-align-pad-left">{rentedTo}</td>
                        </tr>
                    </table>
                </div>
                <div className="overlay" onClick={handleClick}>
                </div>
            </div>
            {showInfo && (
                <>
                    <div className="user-overlay" onClick={closeRentableInfo}></div>
                    <LogDataFrame rentData={rentData} />
                </>
            )
            }
        </div>
    )
}

export function ManageRentablesFrame(props: { searchTermRentedAssets: string }) {
    const { searchTermRentedAssets } = props;
    const { token } = useContext(AuthContext);
    const { profileView } = useContext(UserContext);

    const [rentables, setRentables] = useState<{ asset: Asset, rentData: RentRow[] }[]>([])
    const [filteredRentables, setFilteredRentables] = useState<{ asset: Asset, rentData: RentRow[] }[]>([])
    const [showOnlyUpcoming, setShowOnlyUpcoming] = useState(false);

    useEffect(() => {
        loadRentedAssets()
    }, [showOnlyUpcoming]);

    useEffect(()=> {
        const newFilteredRents = rentables.filter(obj => String(obj.asset.aid).startsWith(searchTermRentedAssets) || obj.asset.name.toLowerCase().startsWith(searchTermRentedAssets.toLowerCase()))
        setFilteredRentables(newFilteredRents)
    }, [searchTermRentedAssets])

    const loadRentedAssets = async () => {
        if (!token) return;
        if (!profileView) return;

        const allAssets = await getAssetsOfUser(token)
        if (allAssets === null) {
            alert("Ein Ladefehler ist aufgetreten")
        }

        const rentableAssets = allAssets.filter(asset => {
            if (asset.data.find(entry => entry.dataType == AssetDataEntryType.RENTABLE)) {
                return true;
            }
            return false;
        })

        const assetsWithRentData = rentableAssets.map(asset => {
            let rentEntry = asset.data.find(entry => entry.dataType == AssetDataEntryType.RENTABLE)
            if (rentEntry !== undefined) {
                const rentData = compileRentData(rentEntry.data).filter(row => row.userID === profileView.uid)
                return { asset: asset, rentData: rentData }
            }
            return { asset: asset, rentData: [] }
        }).filter( val => val.rentData.length !== 0)

        let relevant = assetsWithRentData
        if (showOnlyUpcoming) {
            relevant = assetsWithRentData.filter(obj => {
                const today = dayjs().startOf("day")
                const dateFormat = "DD-MM-YYYY"
                if (obj.rentData.find(row => dayjs(row.to, dateFormat).isAfter(today))) return true;
                return false;
            })
        }

        setRentables(relevant)
        setFilteredRentables(relevant)
        loadUserNames(relevant);
    }

    const loadUserNames = async (oldRentables: { asset: Asset, rentData: RentRow[] }[]) => {
        if (!token) return;
        if (!profileView) return;

        const newRentables = oldRentables.map(async obj => {
            const newRentData = obj.rentData.map(
                async row => {
                    if (row.userID == profileView.uid) {
                        row.userName = profileView.email
                    } else {
                        const userName = await getEmailOrName(row.userID, token)
                        row.userName = userName || "Ladefehler";
                    }
                    return row;
                }
            )
            return { asset: obj.asset, rentData: await Promise.all(newRentData) }
        })

        Promise.all(newRentables).then(
            newRents => setRentables(newRents)
        )
    }

    return (
        <>
            <button
                onClick={() => setShowOnlyUpcoming(!showOnlyUpcoming)}        >
                {showOnlyUpcoming ? "Zeige alte Einträge" : "Zeige nur aktuelle Einträge"}
            </button>
            <div className='horizontal-div'>
                <div className='documents-grid'>
                    {Object.values(filteredRentables).map((obj) => (
                        <RentableTile asset={obj.asset} rentData={obj.rentData} />
                    ))}
                </div>
            </div>
        </>
    )
}