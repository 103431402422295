import React, { useContext, useState } from "react";
import { fetchLoginPassword } from "../utils/RequestApi";
import { CryptIDContext } from "../context/CryptIDContext";
import { AuthContext, setTimerToAlertTokenExpiration } from "../context/AuthTokenContext";

import tum_logo from '../assets/tum_logo.png';
import catena_logo from '../assets/Logo_white.svg';
import perper_logo from '../assets/perper_logo.png';
import { UserContext, setUserContext } from "../context/UserContext";

type LoginProps = {
    navigateTo: (page: 'main' | 'login' | 'register' | 'home' | 'forgotPassword') => void;
};

const Login: React.FC<LoginProps> = ({ navigateTo }) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const cryptID = useContext(CryptIDContext);
    const { setToken } = React.useContext(AuthContext);
    const { setProfileView, setUserName, setIsAdmin } = React.useContext(UserContext);

    const handleLogin = async () => {
        if (email === '' || password === '') {
            alert(" Bitte überprüfe deine Login Daten.");
            return;
        }
        try {
            const isLoggedIn = await fetchLoginPassword(email.toLowerCase(), password, cryptID,
                (token: string | null) => { setToken(token); setTimerToAlertTokenExpiration(token); setUserContext(token, setProfileView, setUserName) },
                a => setIsAdmin(a));
            if (isLoggedIn) {
                navigateTo('home');
            }
        } catch (error) {
            alert("Login fehlgeschlagen! Bitte überprüfe deine Login Daten und versuche es erneut.");
        }
    };
    return (
        <div className="login-container">
            <div className="login-logo-container">
                <div className="login-logo-row">
                    <img src={tum_logo} alt="tum" className="login-logo-big" />
                </div>
                <div className="login-logo-row">
                    <span className="digital-contracts">Project name</span>
                </div>
                <div className="login-logo-row">
                    <span className="powered-by">powered by</span>
                </div>
                <div className="login-logo-row">
                    <img src={catena_logo} alt="catena" className="login-logo" />
                </div>
            </div>
            <div className="login-logo-row">
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="login-logo-row">
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <div className="register-link-container">
                <span className="register-link" onClick={() => navigateTo('forgotPassword')}>Password vergessen?</span>
            </div>
            <div className="login-logo-row">
                <button className="login-button" onClick={handleLogin}>Login</button>
            </div>
            <div className="register-link-container">
                <span className="register-link" onClick={() => navigateTo('register')}>Noch keinen Account? Registriere dich hier.</span>
            </div>

        </div>
    );
}



export default Login;
