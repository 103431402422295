import React, { useState } from 'react';
import { fetchUpdatePassword } from '../utils/RequestApi';

import tum_logo from '../assets/tum_logo.png';
import catena_logo from '../assets/Logo_white.svg';
import perper_logo from "../assets/perper_logo.png";

type NewPasswordProps = {
    navigateTo: (page: 'main' | 'login' | 'register' | 'home' | 'forgotPassword' | 'newPassword') => void;
    token: string;
};
const NewPassword: React.FC<NewPasswordProps> = ({ navigateTo, token }) => {
    const [newPassword, setNewPassword] = useState('');

    const handleNewPasswordSubmit = async () => {
        if (!newPassword) {
            alert("Bitte geben Sie ein neues Passwort ein.");
            return;
        }

        try {
            const isSuccess = await fetchUpdatePassword(newPassword, token);
            if (isSuccess) {
                alert("Ihr Passwort wurde erfolgreich zurückgesetzt.");
                navigateTo('login');
            } else {
                alert("Fehler beim Zurücksetzen des Passworts. Bitte versuchen Sie es erneut.");
            }
        } catch (error) {
            console.error("Error updating password:", error);
            alert("Fehler beim Zurücksetzen des Passworts.");
        }
    };
    return (
        <div className="login-container">
            <div className="login-logo-container">
                <div className="login-logo-row">
                    <img src={tum_logo} alt="tum" className="login-logo-big" />
                </div>
                <div className="login-logo-row">
                    <span className="digital-contracts">Project name</span>
                </div>
                <div className="login-logo-row">
                    <span className="powered-by">powered by</span>
                </div>
                <div className="login-logo-row">
                    <img src={catena_logo} alt="catena" className="login-logo" />
                </div>
            </div>
            <div className="login-logo-row">
                <input
                    type="password"
                    placeholder="Neues Passwort"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
            </div>
            <div className="login-logo-row">
                <button onClick={handleNewPasswordSubmit}>Passwort zurücksetzen</button>
            </div>
        </div>
    );
};

export default NewPassword;
