import React, { useCallback, useContext, useEffect, useState } from "react";
import { AssetType } from "../types/AssetType";
import { AssetCategory } from "../types/AssetCategory";
import { AuthContext } from "../context/AuthTokenContext";
import {fetchAssetTypes, getAssetCategories, getAssetsOfUser} from "../utils/RequestApi";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { AssetInfo } from "./AssetInfo";
import {UserContext} from "../context/UserContext";

interface ChooseAssetTypeProps {
    onChooseAssetType: (id: number) => void,
}

export const ChooseAssetType: React.FC<ChooseAssetTypeProps> = (props: { onChooseAssetType: (id: number) => void }) => {
    const { onChooseAssetType } = props;
    const { token } = useContext(AuthContext);

    const [chosenAssetType, setChosenAssetType] = useState<number>(-1);
    const [assetTypesToChooseFrom, setAssetTypesToChooseFrom] = useState<AssetType[]>([]);
    const [assetTypes, setAssetTypes] = useState<AssetType[]>([]);
    const [isLoadingAssetTypes, setIsLoadingAssetTypes] = useState(false);

    const [filterForCategory, setFilterForCategory] = useState<number>(-1);
    const [assetCategories, setAssetCategories] = useState<AssetCategory[]>([]);
    const [isLoadingAssetCategory, setIsLoadingAssetCategory] = useState(false);
    const { profileView } = useContext(UserContext);

    useEffect(() => {
        loadCategories();
        loadAssetTypes();
    }, []);

    const loadCategories = async () => {
        if (!token) return;
        if (isLoadingAssetCategory) return;
        setIsLoadingAssetCategory(true);
        getAssetCategories(token).then(categories => {
            setAssetCategories(categories);
        }).catch(error => {
            alert(`Fehler beim Laden von Element Kategorien: ${error}`);
        }).finally(() => {
            setIsLoadingAssetCategory(false);
        })
    }

    const loadAssetTypes = async () => {
        if (!token || isLoadingAssetTypes) return;

        setIsLoadingAssetTypes(true);
        try {
            const aTypes = await fetchAssetTypes(token, true);
            const assets = await getAssetsOfUser(token);
            const hasTypeOne = assets.some(asset => asset.type === 1 && asset.oid === profileView?.uid);
            if (hasTypeOne) {
                const filteredTypes = aTypes.filter(type => type.id !== 1);
                setAssetTypesToChooseFrom(filteredTypes);
                setAssetTypes(filteredTypes);
            } else {
                setAssetTypesToChooseFrom(aTypes);
                setAssetTypes(aTypes);
            }
            setChosenAssetType(-1);
        } catch (error) {
            alert(`Error loading asset types: ${error}`);
        } finally {
            setIsLoadingAssetTypes(false);
        }
    };

    const setCategoryToFilterFor = (e: SelectChangeEvent<number>) => {
        const value = Number(e.target.value);
        setFilterForCategory(value);
        if (value === -1) {
            setAssetTypesToChooseFrom(assetTypes);
        } else {
            setAssetTypesToChooseFrom(assetTypes.filter(aType => aType.category === value));
        }
    }

    const handleChooseAssetType = (e: SelectChangeEvent<number>) => {
        const value = Number(e.target.value);
        setChosenAssetType(value);
        onChooseAssetType(value);
    }

    return (
        <div className="choose-asset-type-for-new-asset-frame">
            <h3 className="underlined">Wähle Element Typ:</h3 >
            <table>
                <tr>
                    <td>Filter nach Kategorie:</td>
                    <td>
                        <FormControl fullWidth>
                            <InputLabel>Kategorie</InputLabel>
                            <Select
                                value={filterForCategory}
                                label="Kategorie"
                                onChange={setCategoryToFilterFor}
                            >
                                <MenuItem value={-1}>Kein Filter</MenuItem>
                                {assetCategories.map(category => {
                                    return (<MenuItem value={category.id}>{category.name}</MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                    </td>
                </tr>
                <tr className="asset-type-param-table-wider">
                    <td>Element Typ:</td>
                    <td>
                        <FormControl fullWidth>
                            <InputLabel>Typ</InputLabel>
                            <Select
                                value={chosenAssetType}
                                label="Typ"
                                onChange={handleChooseAssetType}
                            >
                                {assetTypesToChooseFrom.map(aType => {
                                    return (<MenuItem value={aType.id}>{aType.name}</MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                    </td>
                </tr>
            </table>
        </div>
    )
}


interface CreateAssetProps {
    onClose: () => void,
}

export const CreateAsset: React.FC<CreateAssetProps> = (props: { onClose: () => void }) => {
    const { onClose } = props;

    const [isShowingNewAsset, setIsShowingNewAsset] = useState(false);
    const [assetTypeOfNewAsset, setAssetTypeOfNewAsset] = useState(-1);

    const onChooseAssetType = (id: number) => {
        setAssetTypeOfNewAsset(id);
        setIsShowingNewAsset(true)
    }

    const onSave = () => {
        onClose();
    }

    return (
        <>
            <div className="user-overlay" onClick={onClose}></div>
            <ChooseAssetType onChooseAssetType={onChooseAssetType} />
            {isShowingNewAsset && (
                <AssetInfo onClose={onClose} asset={null} isEditable={true} createNew={true} typeNew={assetTypeOfNewAsset} onSave={onSave}  onUpdate={() => {}}/>
            )}
        </>
    )
}