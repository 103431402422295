import React, { useContext, useEffect, useState } from 'react';
import Login from './components/Login';
import Register from './components/Register';
import Home from './components/Home';
import tum_logo from './assets/tum_logo.png';
import './App.css';
import { CryptID, getCryptID } from './utils/CryptId';
import { CryptIDContext } from './context/CryptIDContext';
import catena_logo from './assets/Logo_white.svg';
import Typewriter from 'typewriter-effect';
import { fetchLoginCrypt, submitVerificationToken } from "./utils/RequestApi";
import { AuthContext, setTimerToAlertTokenExpiration } from "./context/AuthTokenContext";
import { UserContext, setUserContext } from './context/UserContext';
import PasswordReset from "./components/PasswordReset";
import NewPassword from "./components/NewPassword";
import perper_logo from "./assets/perper_logo.png";

const App: React.FC = () => {
    const [cryptID, setCryptID] = useState<CryptID | null>(null);
    const [page, setPage] = useState<'main' | 'login' | 'register' | 'home' | 'forgotPassword' | 'newPassword' >('main');
    const { token, setToken } = useContext(AuthContext);
    const { setProfileView, setUserName, setIsAdmin } = useContext(UserContext);
    const [resetToken, setResetToken] = useState('');

    const navigateTo = (page: 'main' | 'login' | 'register' | 'home' | 'forgotPassword' | 'newPassword' ) => {
        setPage(page);
    };

    useEffect(() => {
        const initializeCryptID = async () => {
            const id = await getCryptID("private5.pem", "public5.pem");
            setCryptID(id);
        };
        initializeCryptID();
    }, [setCryptID]);

    useEffect(() => {
        document.title = "Perper / Streetopia";
        const path = window.location.pathname;
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        if (token) {
            if (path.includes('/verify-email')) {
                submitVerificationToken(token)
                    .then((isVerified) => {
                        if (isVerified) {
                            alert('Email erfolgreich verifiziert! Du kannst dich nun einloggen nachdem du von Streetopia akzeptiert wurdest.');
                            window.history.pushState({}, '', '/');
                            setPage('login');
                        } else {
                            alert('Email verifizieren fehlgeschlagen!');
                        }
                    })
                    .catch((error) => {
                        console.error("Verification failed:", error);
                        alert('Email verifizieren fehlgeschlagen!');
                    });
            } else if (path.includes('/reset-password')) {
                setPage('newPassword');
                setResetToken(token);
            }
        }
    }, []);
    useEffect(() => {
        if (page === 'main' && cryptID) {
            const loginCryptID = async () => {
                try {
                    const fetchedToken = await fetchLoginCrypt(cryptID, a => setIsAdmin(a));
                    if (fetchedToken) {
                        setToken(fetchedToken);
                        setTimerToAlertTokenExpiration(fetchedToken)
                        setUserContext(fetchedToken, setProfileView, setUserName);
                        navigateTo('home');
                    }
                } catch (error) {
                    console.error("Error during fetchLoginCrypt:", error);
                }
            }
            loginCryptID();
        }
    }, [cryptID, page, setToken, token]);

    return (
        <CryptIDContext.Provider value={cryptID}>
            <div className={`App ${page === 'home' ? 'homeBackground' : page === 'login' || page === 'register' || page === 'main' || page === 'forgotPassword' || page === 'newPassword' ? 'loginBackground' : ''}`}>
                {page === 'main' && (
                    <div className="login-container">
                        <div className="login-logo-container">
                            <div className="login-logo-row">
                                <img src={tum_logo} alt="tum" className="login-logo-big" />
                            </div>
                            <div className="login-logo-row">
                                <span className="digital-contracts">Project name</span>
                            </div>
                            <div className="login-logo-row">
                                <span className="powered-by">powered by</span>
                            </div>
                            <div className="login-logo-row">
                                <img src={catena_logo} alt="catena" className="login-logo" />
                            </div>
                        </div>
                        <div className="welcome-message">
                            <Typewriter options={{ wrapperClassName: "welcome-message", autoStart: true, loop: true, delay: 80, strings: ["Welcome!", "Willkommen!", "Bienvenido!", "Benvenuto!", "Witamy!", "Accueillir!", "Vítejte!", "Huānyíng!"] }} />
                        </div>
                        <div className="login-logo-row">
                            <button className="login-button" onClick={() => navigateTo('login')}>Login</button>
                            <button className="login-button" onClick={() => navigateTo('register')}>Register</button>
                        </div>
                    </div>
                )}

                {page === 'login' && (
                    <div>
                        <Login navigateTo={navigateTo} />
                    </div>
                )}

                {page === 'register' && (
                    <div>
                        <Register navigateTo={navigateTo} />
                    </div>
                )}
                {page === 'home' && <Home navigateTo={navigateTo} />}
                {page === 'forgotPassword' && (
                    <div>
                        <PasswordReset navigateTo={navigateTo} />
                    </div>
                )}
                {page === 'newPassword' && (
                    <div>
                        <NewPassword navigateTo={navigateTo} token={resetToken} />
                    </div>
                )}

            </div>
        </CryptIDContext.Provider>
    );
}
export default App;
