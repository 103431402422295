
import { useContext, useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/de';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from '@mui/material/styles';
import { UserContext } from '../context/UserContext';
import { getEmailOrName } from '../utils/RequestApi';
import { AuthContext } from '../context/AuthTokenContext';

export function compileRentData(rentData: string) {
    const rows = rentData.split("\n").slice(1)
    const newRentRows = rows.map(row => {
        const rowEntries = row.split(";")
        return {
            entryDate: dayjs(rowEntries[0]),
            userID: Number(rowEntries[1]),
            from: rowEntries[2],
            to: rowEntries[3],
        } as RentRow
    }).sort((a, b) => {
        // sort by fromDate
        const aFrom = dayjs(a.from)
        const bFrom = dayjs(b.from)
        if (aFrom > bFrom) {
            return 1;
        }
        if (bFrom > aFrom) {
            return -1;
        }
        return 0;
    })
    return newRentRows;
}

dayjs.extend(isBetweenPlugin);

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
    isBlockedDay: () => number;
}

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered' && prop !== 'isBlockedDay' && prop !== 'onDayClicked',
})<CustomPickerDayProps>(({ theme, isBlockedDay }) => ({
    borderRadius: 0,
    ...(isBlockedDay() === 0 && {
        backgroundColor: "none",
        color: "black",
        boxShadow: "none",
    }),
    ...(isBlockedDay() !== 0 && {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main,
        },
    }),
})) as React.ComponentType<CustomPickerDayProps>;


function Day(
    props: PickersDayProps<Dayjs> & {
        selectedDay?: Dayjs | null;
        hoveredDay?: Dayjs | null;
        isBlockedDay?: () => number;
        onDayClicked?: (d: Dayjs) => void;
    },
) {
    const { day, selectedDay, hoveredDay, isBlockedDay, onDayClicked, ...other } = props;

    return (
        <CustomPickersDay
            {...other}
            onClick={() => onDayClicked ? onDayClicked(day) : {}}
            day={day}
            sx={{ px: 2.5 }}
            disableMargin
            selected={false}
            isBlockedDay={isBlockedDay ? isBlockedDay : () => 0}
        />
    );
}

export default function WeekPicker(props: { rentData: RentRow[] }) {
    const { rentData } = props;
    const { token } = useContext(AuthContext)
    const [hoveredDay, setHoveredDay] = useState<Dayjs | null>(null);
    const [value, setValue] = useState<Dayjs | null>(dayjs());
    const [highlightedDays, setHighlightedDays] = useState<Dayjs[]>([]);

    useEffect(() => {
        computeAndSetHighlightedDays(dayjs().startOf("month"))
    }, []);

    function isBlockedDay(day: Dayjs | null): number {
        if (day === null) return 0;
        if (highlightedDays.find(n => n.isSame(day, "day"))) {
            return 1;
        }
        return 0;
    }

    const computeAndSetHighlightedDays = (date: Dayjs) => {
        const dateFormat = "DD-MM-YYYY"

        const relevant_start = date.startOf("month").add(-8, "day")
        const relevant_end = date.endOf("month").add(8, "day")
        const relevant = rentData.filter(e => {
            const from = dayjs(e.from, dateFormat)
            const to = dayjs(e.to, dateFormat)
            if (from.isBetween(relevant_start, relevant_end) || to.isBetween(relevant_start, relevant_end) || (relevant_start.isAfter(from) && relevant_end.isBefore(to))) {
                return true
            }
            return false
        })

        const slices = relevant.map(e => {
            const fromDay = dayjs(e.from, dateFormat).startOf("day")
            const toDay = dayjs(e.to, dateFormat).endOf("day")
            return { start: fromDay, end: toDay }
        })

        const newHighlightedDays = [] as Dayjs[]
        slices.forEach(slice => {
            for (let d = slice.start; d.isBefore(slice.end); d = d.add(1, "day")) {
                newHighlightedDays.push(d);
            }
        })
        setHighlightedDays(newHighlightedDays)
    }

    const handleMonthChange = (date: Dayjs) => {
        setHighlightedDays([]);
        computeAndSetHighlightedDays(date);
    };

    const onDayClicked = async (d: Dayjs) => {
        if (!token) return

        if (!highlightedDays.find(otherDay => otherDay.isSame(d, "day"))) {
            return
        }
        // find relevant rent row

        const relevantRentRow = rentData.find(row => {
            const dateFormat = "DD-MM-YYYY"
            const from = dayjs(row.from, dateFormat).startOf("day")
            const to = dayjs(row.to, dateFormat).endOf("day")
            return d.add(20, "minute").isBetween(from, to)
        })

        if (relevantRentRow) {
            const userName = await getEmailOrName(relevantRentRow.userID, token)
            const msg =
                `Mieter: ${userName || `uid: ${relevantRentRow.userID}`}
Von: ${relevantRentRow.from}
Bis: ${relevantRentRow.to}`
            alert(msg)
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
                value={value}
                onChange={(newValue) => setValue(newValue)}
                showDaysOutsideCurrentMonth
                onMonthChange={handleMonthChange}
                slots={{ day: Day }}
                slotProps={{
                    day: (ownerState) =>
                    ({
                        selectedDay: value,
                        hoveredDay,
                        onPointerEnter: () => setHoveredDay(ownerState.day),
                        onPointerLeave: () => setHoveredDay(null),
                        isBlockedDay: () => isBlockedDay(ownerState.day),
                        onDayClicked: onDayClicked,
                    } as any),
                }}
            />
        </LocalizationProvider>
    );
}


export type RentRow = {
    entryDate: Dayjs;
    userID: number;
    userName?: string;
    from: string;
    to: string;
}

export function RentableFrame(props: { rentData: string, onRent: (a: RentRow) => void }) {
    const { rentData, onRent } = props;
    const { profileView } = useContext(UserContext);

    const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs());
    const [toDate, setToDate] = useState<Dayjs | null>(dayjs());

    const [allBlockedDays, setAllBlockedDays] = useState<Dayjs[]>([]);

    useEffect(() => {
        const computeAllBlockedDays = () => {
            const dateFormat = "DD-MM-YYYY"
            const data = compileRentData()
            const slices = data.map(e => {
                const fromDay = dayjs(e.from, dateFormat).startOf("day")
                const toDay = dayjs(e.to, dateFormat).endOf("day")
                return { start: fromDay, end: toDay }
            })

            const newHighlightedDays = [] as Dayjs[]
            slices.forEach(slice => {
                for (let d = slice.start; d.isBefore(slice.end); d = d.add(1, "day")) {
                    newHighlightedDays.push(d);
                }
            })
            setAllBlockedDays(newHighlightedDays)
        }
        computeAllBlockedDays()
    }, []);

    const compileRentData = () => {
        const rows = rentData.split("\n").slice(1)
        const newRentRows = rows.map(row => {
            const rowEntries = row.split(";")
            return {
                entryDate: dayjs(rowEntries[0]),
                userID: Number(rowEntries[1]),
                from: rowEntries[2],
                to: rowEntries[3],
            } as RentRow
        }).sort((a, b) => {
            // sort by fromDate
            const aFrom = dayjs(a.from)
            const bFrom = dayjs(b.from)
            if (aFrom > bFrom) {
                return 1;
            }
            if (bFrom > aFrom) {
                return -1;
            }
            return 0;
        })
        return newRentRows;
    }

    const getCalendar = () => {
        return <WeekPicker rentData={compileRentData()} />

    }

    const isTimeFrameFree = (from: Dayjs, to: Dayjs) => {
        if (allBlockedDays.find(d => {
            return d.add(2, "second").isBetween(from.startOf("day"), to.endOf("day"))
        })) {
            return false
        }
        return true;
    }

    const handleRent = () => {
        if (!profileView) return;
        if (toDate === null) {
            alert("Bitte gebe ein 'Bis' Datum ein");
            return;
        }
        if (fromDate === null) {
            alert("Bitte gebe ein 'Von' Datum ein");
            return;
        }

        const saveNewRow = () => {
            const uid = profileView.uid
            const newRentRow = {
                entryDate: dayjs(),
                userID: uid,
                from: fromDate.format("DD-MM-YYYY"),
                to: toDate.format("DD-MM-YYYY"),
            } as RentRow
            onRent(newRentRow);
        }
        if (toDate.isBefore(fromDate)) {
            alert("Ungültiger Zeitraum: Der 'Bis'-Zeitpunkt muss nach 'Von' sein")
            return
        }
        if (dayjs().startOf("day").isAfter(fromDate)) {
            alert("Ungültiger Zeitraum: Du kannst nur in einem Zeitraum nach heute mieten")
            return
        }

        if (isTimeFrameFree(fromDate, toDate)) {
            const confirm = window.confirm('Soll das Asset für den angegebenen Zeitraum gemietet werden?')
            if (confirm) {
                saveNewRow()
            }
        } else {
            alert("Der angefragte Zeitraum kann nicht gemietet werden. Es gibt Überschneidung mit geblockten Zeiträumen")
        }

    }

    return (
        <div
            className="rentable-frame"
            onClick={e => { e.stopPropagation() }}>
            <div>
                <h3>Miete Element</h3>
                <table>
                    <tr>
                        <td>Von</td>
                        <td>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                <DatePicker
                                    value={fromDate}
                                    onChange={(newValue) => setFromDate(newValue)} />
                            </LocalizationProvider>
                        </td>
                    </tr>
                    <tr>
                        <td>Bis</td>
                        <td>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                <DatePicker
                                    value={toDate}
                                    onChange={(newValue) => setToDate(newValue)} />
                            </LocalizationProvider>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button onClick={handleRent}>
                                Mieten
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                {getCalendar()}
            </div>
        </div>
    )
}
