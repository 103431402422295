import React, {useContext, useEffect, useState} from 'react';
import {Profile} from '../types/Profile';
import {FiArrowLeft, FiEdit} from 'react-icons/fi';
import {AuthContext} from "../context/AuthTokenContext";
import {updateProfile} from "../utils/RequestApi";

interface UserProfileProps {
    onClose: () => void;
    profile: Profile | null;
    isEditable: boolean;
}

export const UserProfile: React.FC<UserProfileProps> = ({onClose, profile, isEditable}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [updatedProfile, setUpdatedProfile] = useState(profile || new Profile("", ""));
    const {token} = useContext(AuthContext);
    const [displayProfile, setDisplayProfile] = useState(profile || new Profile("", ""));

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = () => {
        if (!token) return;
        updateProfile(updatedProfile, token)
            .then(success => {
                if (success) {
                    setDisplayProfile(updatedProfile);
                } else {
                }
            })
            .catch(error => {
                console.error('Fehler beim Updaten vom Profil:', error);
            });
        setIsEditing(false);
    };

    const getInitials = () => {
        if (!updatedProfile) return '??';
        return `${updatedProfile.givenName.charAt(0)}${updatedProfile.surname.charAt(0)}`;
    };

    useEffect(() => {
        setUpdatedProfile(profile || new Profile("", ""));
        setDisplayProfile(profile || new Profile("", ""));
    }, [profile])
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const newProfile = new Profile(
            updatedProfile.givenName,
            updatedProfile.surname,
            updatedProfile.postleitzahl,
            updatedProfile.wohnort,
            updatedProfile.strasse,
            updatedProfile.hausnummer,
            updatedProfile.telefonnummer,
            updatedProfile.rolle
        );
        (newProfile as any)[field] = event.target.value;
        setUpdatedProfile(newProfile);
    };

    return (
        <div className="user-profile">
            <div className="header">
                <FiArrowLeft className="icon back-icon" onClick={onClose}/>
                <div className="avatar" style={{backgroundColor: "blue"}}>
                    {getInitials()}
                </div>
                {!isEditable && <FiEdit className="icon edit-icon-transparent"/>}
                {isEditable && <FiEdit className="icon edit-icon" onClick={handleEdit}/>}
            </div>

            <div className="divider"></div>
            <>
            { isEditing ? (
                                <div className="user-info">
                                    <div className="tooltip">
                                        <input
                                            type="text"
                                            value={updatedProfile.givenName}
                                            onChange={e => handleInputChange(e, 'givenName')}
                                            placeholder="Vorname"
                                        />
                                        <span className="tooltip-text">Gebe deinen Vornamen ein</span>
                                    </div>
                                    <div className="tooltip">
                                        <input
                                            type="text"
                                            value={updatedProfile.surname}
                                            onChange={e => handleInputChange(e, 'surname')}
                                            placeholder="Nachname"
                                        />
                                        <span className="tooltip-text">Gebe deinen Nachnamen ein</span>
                                    </div>
                                    <div className="tooltip">
                                        <input
                                            type="text"
                                            value={updatedProfile.postleitzahl || ''}
                                            onChange={e => handleInputChange(e, 'postleitzahl')}
                                            placeholder="Postleitzahl"
                                        />
                                        <span className="tooltip-text">Gebe deine Postleitzahl ein</span>
                                    </div>
                                    <div className="tooltip">
                                        <input
                                            type="text"
                                            value={updatedProfile.wohnort || ''}
                                            onChange={e => handleInputChange(e, 'wohnort')}
                                            placeholder="Stadt"
                                        />
                                        <span className="tooltip-text">Gebe deine Stadt ein</span>
                                    </div>
                                    <div className="tooltip">

                                        <input
                                            type="text"
                                            value={updatedProfile.strasse || ''}
                                            onChange={e => handleInputChange(e, 'strasse')}
                                            placeholder="Straße"
                                        />
                                        <span className="tooltip-text">Gebe deine Straße ein</span>
                                    </div>
                                    <div className="tooltip">
                                        <input
                                            type="text"
                                            value={updatedProfile.hausnummer || ''}
                                            onChange={e => handleInputChange(e, 'hausnummer')}
                                            placeholder="Haus Nummer"
                                        />
                                        <span className="tooltip-text">Geben deine Hausnummer ein</span>
                                    </div>
                                    <div className="tooltip">
                                        <input
                                            type="text"
                                            value={updatedProfile.telefonnummer || ''}
                                            onChange={e => handleInputChange(e, 'telefonnummer')}
                                            placeholder="Telefon Nummer"
                                        />
                                        <span className="tooltip-text">Gebe deine Telefon Nummer ein</span>
                                    </div>
                                    <div className="tooltip">
                                        <input
                                            type="text"
                                            value={updatedProfile.rolle || ''}
                                            onChange={e => handleInputChange(e, 'rolle')}
                                            placeholder="Rolle"
                                        />
                                        <span className="tooltip-text">Gebe deine Rolle ein</span>
                                    </div>
                                </div>
            ) : profile ? (
                (profile.surname !== "" && profile.givenName !== "") ? (
                                <div className="user-info">
                                    <h2>{displayProfile.givenName} {displayProfile.surname}</h2>
                                    <h4>Addresse:</h4>
                                    <address>
                                        {displayProfile.strasse} {displayProfile.hausnummer}<br/>
                                        {displayProfile.postleitzahl} {displayProfile.wohnort}<br/>
                                    </address>
                                    <p>Telefon: {displayProfile.telefonnummer}</p>
                                    <p>Rolle: {displayProfile.rolle}</p>
                                </div>
                        ) : (
                            <div className="user-info">
                                <p>Keine User Informationen verfügbar.</p>
                            </div>
                        )
                ) : (
                <div className="user-info">
                    <p>Keine User Informationen verfügbar.</p>
                </div>
            )}
                { isEditing && (
                    <button onClick={handleSave}>Speichern</button>
                )}
                </>
        </div>
    )
}


export default UserProfile;
