export class AssetHistory {
    constructor(
        public id: number,
        public assetId: number,
        public eventType: string,
        public eventDescription: string,
        public amount: number,
        public amountAfterEvent: number,
        public senderId: number,
        public receiverId: number,
        public transactionId: string,
        public createdAt: string,
        public transUid: string,
        public senderEmail?: string,
        public receiverEmail?: string
    ) {}

    static fromJson(json: any): AssetHistory {
        return new AssetHistory(
            json.id,
            json.aid,
            json.event_type,
            json.event_description,
            json.amount,
            json.balance_after_event,
            json.sender_id,
            json.receiver_id,
            json.transaction_id,
            json.created_at,
            json.uid_trans
        );
    }
}
