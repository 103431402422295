
/*from: https://stackoverflow.com/questions/42136098/array-groupby-in-typescript  by: kevinrodriguez-io*/
export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);


export function getLogoSrc(fileType: string): string {
  // when build for vm we need to edit the access path because of prxying
  let build_for_vm_prefix = ""
  build_for_vm_prefix = "" // "/assets" // this is the proxy subpath

  let logoSrc = '';
  switch (fileType.toLowerCase()) {
    case 'pdf':
      logoSrc = process.env.PUBLIC_URL + '/assets/pdf_logo.jpg';
      break;
    case 'docx':
    case 'doc':
      logoSrc = process.env.PUBLIC_URL + '/assets/docx_logo.jpg';
      break;
    case 'csv':
    case 'xls':
    case 'xlsx':
      logoSrc = process.env.PUBLIC_URL + '/assets/xlsx_logo.png';
      break;
    case 'ppt':
    case 'pptx':
      logoSrc = process.env.PUBLIC_URL + '/assets/pptx_logo.png';
      break
    case 'txt':
      logoSrc = process.env.PUBLIC_URL + '/assets/txt_logo.png';
      break
    case 'png':
    case 'gif':
    case 'bmp':
    case 'jpg':
    case 'jpeg':
      logoSrc = process.env.PUBLIC_URL + '/assets/picture_logo.jpg';
      break;
    default:
      logoSrc = process.env.PUBLIC_URL + '/assets/unknown_logo.jpg';
  }
  return build_for_vm_prefix + logoSrc
}