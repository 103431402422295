type AssetCategoryJSON = {
    id: number;
    name: string;
    chain_id: string;
}

export class AssetCategory {
    id: number;
    name: string;
    chain_id: string;

    constructor(id: number, name: string, chain_id: string) {
        this.id = id;
        this.name = name;
        this.chain_id = chain_id;
    }

    static fromJson(json: Record<string, any>): AssetCategory {
        return new AssetCategory(
            json['id'] || -1,
            json['name'] || "",
            json['chain_id'] || "",
        )
    }

    toJson(): Record<string, any> {
        return {
            "id": this.id,
            "name": this.name,
            "chain_id": this.chain_id,
        }
    }

    static fromMap(json: Record<string, any>): AssetCategory {
        return new AssetCategory(
            json.get('id') || -1,
            json.get('name') || "",
            json.get('chain_id') || "",
        );
    }

    static fromList(jsonList: AssetCategoryJSON[] | null): AssetCategory[] {
        if (!jsonList) return [];
        return jsonList.map(json => AssetCategory.fromJson(json));
    }
}
