import { createContext } from 'react';
import { ProfileView } from '../types/Profile';
import { getEmailOrName, getOwnProfileView, getOwnUserDetails } from '../utils/RequestApi';

export interface UserContextProps {
    userName: string | null;
    setUserName: (uname: string | null) => void;
    profileView: ProfileView | null;
    setProfileView: (p: ProfileView | null) => void;
    isAdmin: boolean;
    setIsAdmin: (admin: boolean) => void;
}

export const UserContext = createContext<UserContextProps>({
    userName: null,
    setUserName: () => { },
    profileView: null,
    setProfileView: () => { },
    isAdmin: false,
    setIsAdmin: () => { }
});

export async function setUserContext(
    token: string | null,
    setProfileView: (p: ProfileView | null) => void,
    setUserName: (uname: string | null) => void): Promise<boolean> {
    if (!token) return false;

    const updateUserContext = () => getOwnProfileView(token, (p: ProfileView) => {
        setProfileView(p);
        getEmailOrName(p.uid, token).then(uname => {
            if (!uname) return;
            setUserName(uname);
        })
    })
    return await updateUserContext();
}