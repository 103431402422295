import { CryptID } from "./CryptId";
import utf8 from 'crypto-js/enc-utf8';
import { Document } from "../types/Document";
import { User } from "../types/User";
import sha256 from 'crypto-js/sha256';
import CryptoJS from 'crypto-js';
import base64 from 'crypto-js/enc-base64';
import { Profile, ProfileView } from "../types/Profile";
import { Asset, AssetView } from "../types/Asset";
import { Right } from "../types/Right";
import { AssetType } from "../types/AssetType";
import { AssetCategory } from "../types/AssetCategory";
import {AssetHistory} from "../types/AssetHistory";


const apiUrl = "https://perper.catena.software/api/" // "https://dbmvm1.cit.tum.de/assets/";
//const apiUrl = "http://localhost:8081/" // "https://dbmvm1.cit.tum.de/assets/";


export async function fetchLoginCrypt(id: CryptID | null, onAdminReceived: (admin: boolean) => void): Promise<string | null> {
    try {
        if (!id
        ) {
            return null;
        }
        const response = await fetch(`${apiUrl}user/auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                cryptID: id.toJson(),
            })
        });

        if (response.status === 200) {
            const json = await response.json();
            if (json.error) {
                throw new Error(`Status Error: ${json.message}`);
            }
            onAdminReceived(json["admin"])
            return json["token"];
        } else {
            return null;
        }
    } catch (error) {
        if (error instanceof TypeError) {
            throw new Error("Server Error.");
        }
        throw new Error("Timeout.");
    }
}

export async function fetchLoginPassword(email: string, password1: string, id: CryptID | null, onTokenReceived: (token: string) => void, onAdminReceived: (admin: boolean) => void): Promise<boolean> {
    try {
        if (!id
        ) {
            throw new Error("CryptId is not initialized");
        }

        const salt = 'UVocjgjgXg8P7zIsC93kKlRU8sPbTBhsAMFLnLUPDRYFIWAk';
        const saltedPassword = utf8.parse(salt + password1);
        const password = sha256(saltedPassword).toString();

        const response = await fetch(`${apiUrl}user/auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                email,
                password,
                cryptID: id.toJson(),
            })
        });


        if (response.status === 200) {
            const json = await response.json();
            onTokenReceived(json["token"]);
            onAdminReceived(json["admin"])
            return true;
        } else {
            throw new Error("Wrong password or email!");
        }
    } catch (error) {
        if (error instanceof TypeError) {
            throw new Error("Server Error.");
        }
        throw new Error("Timeout.");
    }
}

export async function getOwnProfileView(token: string, onProfileReceived: (p: ProfileView) => void): Promise<boolean> {
    const url = `${apiUrl}user/me`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };
    try {
        const response = await fetch(url, requestOptions);
        if (response.status === 200) {
            const json = await response.json();
            onProfileReceived(ProfileView.fromJson(json));
            return true;
        } else {
            return false;
        }
    } catch (error) {
        alert("Konnte User Info nicht laden!")
    }
    return false;
}

export async function getDocuments(offset: number, limit: number, token: string): Promise<Document[]> {
    const url = `${apiUrl}user/documents`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
        'Offset': offset.toString(),
        'Limit': limit.toString()
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonList = await response.json();
            return Document.fromList(jsonList);
        } else {
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}

export async function fetchPasswordReset(email: string): Promise<boolean> {
    const encodedEmail = encodeURIComponent(email);
    const url = `${apiUrl}user/auth/forgot-password/${encodedEmail}`;

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            return true;
        } else {
            console.error("Failed to send password reset request", response.status);
            return false;
        }
    } catch (error) {
        console.error("Error sending password reset request", error);
        return false;
    }
}


export async function getDocumentInfo(did: number, token: string, aid: number = -1): Promise<Document | null> {
    let url = `${apiUrl}user/document/info/${did}`;
    if (aid != -1) {
        url = `${url}/${aid}`
    }
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const json = await response.json();
            return Document.fromJson(json);
        } else {
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getDocument(did: number, token: string, aid: number = -1): Promise<Uint8Array> {
    let url = `${apiUrl}user/document/${did}`;
    if (aid != -1) {
        url = `${url}/${aid}`
    }
    const headers = new Headers();
    headers.append('Authorization', token);
    headers.append('Accept', 'application/pdf');

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: headers,
        });

        if (!response.ok) {
            return new Uint8Array();
        }

        const responseBody = new Uint8Array(await response.arrayBuffer());
        return responseBody;
    } catch (error) {
        return new Uint8Array();
    }
}

export async function getDecryptedDocument(did: number, token: string, aid: number = -1): Promise<Uint8Array> {
    let url = `${apiUrl}user/documents/decrypt/${did}`;
    if (aid != -1) {
        url = `${url}/${aid}`
    }

    const headers = new Headers();
    headers.append('Authorization', token);
    headers.append('Accept', 'application/pdf');

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: headers,
        });

        if (!response.ok) {
            return new Uint8Array();
        }

        const responseBody = new Uint8Array(await response.arrayBuffer());
        return responseBody;
    } catch (error) {
        return new Uint8Array();
    }
}

export async function getUsers(token: string): Promise<User[]> {
    try {
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set("Content-Type", "application/json");
        requestHeaders.set("Accept", "application/json");
        requestHeaders.set("Authorization", token);

        const response = await fetch(`${apiUrl}users`, {
            method: "GET",
            headers: requestHeaders
        });

        if (response.status === 200) {
            const responseBody = await response.json();
            return User.fromList(responseBody);
        } else {
            return [];
        }
    } catch (error) {
        console.error("Error fetching users:", error);
        return [];
    }
}

export async function bookDocumentTransaction(document: File, name: string, token: string): Promise<{ success: boolean, did: number }> {
    try {
        const documentBytes = await new Promise<ArrayBuffer>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target!.result as ArrayBuffer);
            reader.onerror = reject;
            reader.readAsArrayBuffer(document);
        });
        const uint8Array = new Uint8Array(documentBytes);
        const numberArray: number[] = Array.from(uint8Array);
        const wordArray = CryptoJS.lib.WordArray.create(numberArray);
        const hash = sha256(wordArray);
        const password = base64.stringify(hash).substring(0, 16);
        const body = {
            name: name,
            password: password,
            document: numberArray
        };

        const response = await fetch(`${apiUrl}user/document`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(body)
        });
        if (response.ok) {
            const json = await response.json();
            const did = json["did"] as number || -1;
            return { success: true, did: did };
        }
        return { success: false, did: -1 };
    } catch (error) {
        console.error("Error book transaction:", error);
        return { success: false, did: -1 };
    }
}

export async function bookEncryptedDocumentTransaction(document: File, name: string, token: string): Promise<{ success: boolean, did: number }> {
    try {
        const documentBytes = await new Promise<ArrayBuffer>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target!.result as ArrayBuffer);
            reader.onerror = reject;
            reader.readAsArrayBuffer(document);
        });
        const uint8Array = new Uint8Array(documentBytes);
        const numberArray: number[] = Array.from(uint8Array);
        const wordArray = CryptoJS.lib.WordArray.create(numberArray);
        const hash = sha256(wordArray);
        const password = base64.stringify(hash).substring(0, 16);
        const body = {
            name: name,
            password: password,
            document: numberArray
        };

        const response = await fetch(`${apiUrl}user/document/encrypt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(body)
        });

        if (response.ok) {
            const json = await response.json();
            const did = json["did"] as number || -1;
            return { success: true, did: did };
        }
        return { success: false, did: -1 };
    } catch (error) {
        console.error("Error book transaction:", error);
        return { success: false, did: -1 };
    }
}

export async function getOwnUserDetails(token: string): Promise<Profile | null> {
    const url = `${apiUrl}user/profile`;

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonData = await response.json();
            return Profile.fromJson(jsonData);
        } else {
            console.error(`Failed to get user details: ${response.statusText}`);
            return null;
        }
    } catch (error) {
        console.error('An error occurred while getting user details', error);
        return null;
    }
}

export async function updateProfile(profile: Profile, token: string): Promise<boolean> {
    const url = `${apiUrl}user/profile`;

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const body = JSON.stringify({
        name: profile.givenName,
        surname: profile.surname,
        phone: profile.telefonnummer,
        city: profile.wohnort,
        hausnummer: profile.hausnummer,
        strasse: profile.strasse,
        plz: profile.postleitzahl,
        role: profile.rolle
    });

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
        body: body
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonData = await response.json();
            return jsonData.success === true;
        } else {
            console.error(`Failed to update user profile: ${response.statusText}`);
            return false;
        }
    } catch (error) {
        console.error('An error occurred while updating user profile', error);
        return false;
    }
}

export async function getUserDetails(uid: number, token: string): Promise<Map<string, any> | null> {
    const url = `${apiUrl}user/profile/${uid}`;

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonData = await response.json();
            return new Map(Object.entries(jsonData));
        } else {
            alert(`Es existiert keine Information zu diesem User`);
            return null;
        }
    } catch (error) {
        console.error('An error occurred while fetching user details', error);
        return null;
    }
}

export async function getEmailOrName(uid: number, token: string): Promise<string | null> {
    const url = `${apiUrl}user/${uid}`;

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const resultString = await response.text();
            return resultString.substring(1, resultString.length - 2);
        } else {
            alert(`Es existiert keine Information zu diesem User`);
            return null;
        }
    } catch (error) {
        console.error('An error occurred while fetching user details', error);
        return null;
    }
}

export async function getUseChainID(uid: number, token: string): Promise<string | null> {
    const url = `${apiUrl}user/chain/${uid}`;

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const resultString = await response.text();
            return resultString;
        } else {
            alert(`Es existiert keine Information zu diesem User`);
            return null;
        }
    } catch (error) {
        console.error('An error occurred while fetching user details', error);
        return null;
    }
}

export async function addDocument(did: number, uid: number, token: string): Promise<String> {
    const url = `${apiUrl}user/document/add/${did}/${uid}`;

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonData = await response.json();
            return jsonData.documentTaid;
        } else {
            console.error(`Failed to add document: ${response.statusText}`);
            return "";
        }
    } catch (error) {
        console.error('An error occurred while adding the document', error);
        return "";
    }
}

export async function postRegister(email: string, password: string, onTokenReceived: (token: string) => void): Promise<boolean> {
    const url = `${apiUrl}user/auth`;
    const salt = 'UVocjgjgXg8P7zIsC93kKlRU8sPbTBhsAMFLnLUPDRYFIWAk';
    const saltedPassword = utf8.parse(salt + password);
    const password1 = sha256(saltedPassword).toString();
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({
            email: email,
            password: password1,
        }),
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonData = await response.json();
            onTokenReceived(jsonData["token"]);
            return true;
        } else {
            alert('Falsches Password oder Email!');
            return false;
        }
    } catch (error) {
        console.error('An error occurred while trying to register', error);
        return false;
    }
}

export async function deleteDocument(document: Document, token: string): Promise<boolean> {
    const url = `${apiUrl}user/document/${document.did}`;

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonData = await response.json();
            return jsonData.success === true;
        } else {
            console.error(`Failed to delete document: ${response.statusText}`);
            return false;
        }
    } catch (error) {
        console.error('An error occurred while deleting the document', error);
        return false;
    }
}


export async function createAsset(asset: Asset, token: string): Promise<boolean> {
    const url = `${apiUrl}asset`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const body = JSON.stringify(asset.toJson())

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: headers,
        body: body
    };

    try {
        const response = await fetch(url, requestOptions);
        if (response.status === 200) {
            const jsonData = await response.json();
            return jsonData.success === true;
        } else {
            console.error(`Failed to create asset: ${response.statusText}`)
            return false;
        }
    } catch (error) {
        console.error('An error ocurred while creating an asset', error)
        return false;
    }
}

export async function getAssetsOfUser(token: string): Promise<Asset[]> {
    const url = `${apiUrl}asset`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonList = await response.json();
            return Asset.fromList(jsonList);
        } else {
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}

export async function getAsset(aid: number, token: string): Promise<Asset | null> {
    const url = `${apiUrl}asset/${aid}`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const json = await response.json();
            return Asset.fromJson(json);
        } else {
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function updateAsset(asset: Asset, token: string): Promise<boolean> {
    const url = `${apiUrl}asset`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const body = JSON.stringify(asset.toJson())

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
        body: body
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const json = await response.json();
            return json.success === true;
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function deleteAsset(asset: AssetView, token: string): Promise<boolean> {
    const url = `${apiUrl}asset/${asset.aid}`;

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonData = await response.json();
            return jsonData.success === true;
        } else {
            console.error(`Failed to delete asset: ${response.statusText}`);
            return false;
        }
    } catch (error) {
        console.error('An error occurred while deleting the asset', error);
        return false;
    }
}

export async function getOwnRights(token: string): Promise<Right[]> {
    const url = `${apiUrl}rights`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonList = await response.json();
            return Right.fromList(jsonList);
        } else {
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}

export async function getRightsToAsset(token: string, aid: number): Promise<Right[]> {
    const url = `${apiUrl}rights/${aid}`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonList = await response.json();
            return Right.fromList(jsonList);
        } else {
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}

export async function addRight(right: Right, token: string): Promise<boolean> {

    const url = `${apiUrl}rights`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const body = JSON.stringify(right.toJson())

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: headers,
        body: body
    };

    try {
        const response = await fetch(url, requestOptions);
        if (response.status === 200) {
            const jsonData = await response.json();
            return jsonData.success === true;
        } else {
            console.error(`Failed to add right: ${response.statusText}`)
            return false;
        }
    } catch (error) {
        console.error('An error ocurred while adding a right', error)
        return false;
    }
}

export async function updateRights(rights: Right[], token: string): Promise<boolean> {
    const url = `${apiUrl}rights`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const body = JSON.stringify(rights.map(r => r.toJson()))

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
        body: body
    };

    try {
        const response = await fetch(url, requestOptions);
        if (response.status === 200) {
            const jsonData = await response.json();
            return jsonData.success === true;
        } else {
            console.error(`Failed to update rights: ${response.statusText}`)
            return false;
        }
    } catch (error) {
        console.error('An error ocurred while updating rights', error)
        return false;
    }
}

export async function fetchAssetTypes(token: string, onlyInstantiable: boolean = true): Promise<AssetType[]> {
    const url = `${apiUrl}asset/types`;
    const url_instantiable = `${apiUrl}asset/types/instantiable`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(onlyInstantiable ? url_instantiable : url, requestOptions);

        if (response.status === 200) {
            const jsonList = await response.json();
            return AssetType.fromList(jsonList);
        } else {
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}

export async function fetchAssetTypesOfCategory(token: string, category: number, onlyInstantiable: boolean = false): Promise<AssetType[]> {
    let url = `${apiUrl}asset/types/category/${category}`;
    if (onlyInstantiable) {
        url = `${apiUrl}asset/types/instantiable/${category}`;
    }
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonList = await response.json();
            return AssetType.fromList(jsonList);
        } else {
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}

export async function fetchAssetType(typeID: number, token: string): Promise<AssetType | null> {
    const url = `${apiUrl}asset/types/${typeID}`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonList = await response.json();
            return AssetType.fromJson(jsonList);
        } else {
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function addAssetType(assetType: AssetType, token: string): Promise<boolean> {
    const url = `${apiUrl}asset/types`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const body = JSON.stringify(assetType.toJson())

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: headers,
        body: body
    };

    try {
        const response = await fetch(url, requestOptions);
        if (response.status === 200) {
            const jsonData = await response.json();
            return jsonData.success === true;
        } else {
            console.error(`Failed to add asset type: ${response.statusText}`)
            return false;
        }
    } catch (error) {
        console.error('An error ocurred while adding asset type', error)
        return false;
    }
}

export async function updateAssetType(assetType: AssetType, token: string): Promise<boolean> {
    const url = `${apiUrl}asset/types`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const body = JSON.stringify(assetType.toJson())

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
        body: body
    };

    try {
        const response = await fetch(url, requestOptions);
        if (response.status === 200) {
            const jsonData = await response.json();
            return jsonData.success === true;
        } else {
            console.error(`Failed to update asset type: ${response.statusText}`)
            return false;
        }
    } catch (error) {
        console.error('An error ocurred while updating asset type', error)
        return false;
    }
}

export async function deleteAssetType(typeID: number, token: string): Promise<boolean> {
    const url = `${apiUrl}asset/types/${typeID}`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });


    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);
        if (response.status === 200) {
            const jsonData = await response.json();
            return jsonData.success === true;
        } else {
            console.error(`Failed to update asset type: ${response.statusText}`)
            return false;
        }
    } catch (error) {
        console.error('An error ocurred while updating asset type', error)
        return false;
    }
}

export async function getAssetCategories(token: string): Promise<AssetCategory[]> {
    const url = `${apiUrl}asset/category`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });


    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);
        if (response.status === 200) {
            const jsonData = await response.json();
            return AssetCategory.fromList(jsonData);
        } else {
            console.error(`Failed to fetch asset categories: ${response.statusText}`)
            return [];
        }
    } catch (error) {
        console.error('An error ocurred while fetching asset categories', error)
        return [];
    }
}

export async function getAssetCategory(id: number, token: string): Promise<AssetCategory | null> {
    const url = `${apiUrl}asset/category/${id}`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });


    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);
        if (response.status === 200) {
            const jsonData = await response.json();
            return AssetCategory.fromJson(jsonData);
        } else {
            console.error(`Failed to fetch asset categories: ${response.statusText}`)
            return null;
        }
    } catch (error) {
        console.error('An error ocurred while fetching asset categories', error)
        return null;
    }
}

export async function addAssetCategory(assetCategory: AssetCategory, token: string): Promise<number | null> {
    const url = `${apiUrl}asset/category`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });


    const body = JSON.stringify(assetCategory.toJson())

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: headers,
        body: body
    };

    try {
        const response = await fetch(url, requestOptions);
        if (response.status === 200) {
            const jsonData = await response.json();
            if (jsonData["success"]) {
                return Number(jsonData["id"]);
            } else {
                return null
            }
        } else {
            console.error(`Failed to add asset category: ${response.statusText}`)
            return null;
        }
    } catch (error) {
        console.error('An error ocurred while adding asset category', error)
        return null;
    }
}


export async function updateAssetCategory(assetCategory: AssetCategory, token: string): Promise<boolean> {
    const url = `${apiUrl}asset/category`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });


    const body = JSON.stringify(assetCategory.toJson())

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
        body: body
    };

    try {
        const response = await fetch(url, requestOptions);
        if (response.status === 200) {
            const jsonData = await response.json();
            return jsonData["success"]
        } else {
            console.error(`Failed to update asset category: ${response.statusText}`)
            return false;
        }
    } catch (error) {
        console.error('An error ocurred while updating asset category', error)
        return false;
    }
}

export async function deleteAssetCategory(id: number, token: string): Promise<boolean> {
    const url = `${apiUrl}asset/category/${id}`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });


    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: headers
    };

    try {
        const response = await fetch(url, requestOptions);
        if (response.status === 200) {
            const jsonData = await response.json();
            return jsonData["success"]
        } else {
            console.error(`Failed to add asset category: ${response.statusText}`)
            return false;
        }
    } catch (error) {
        console.error('An error ocurred while adding asset category', error)
        return false;
    }
}

export async function fetchUpdatePassword(newPassword: string, token: string): Promise<boolean> {
    const url = `${apiUrl}user/auth/update-password`;
    const salt = 'UVocjgjgXg8P7zIsC93kKlRU8sPbTBhsAMFLnLUPDRYFIWAk';
    const saltedPassword = utf8.parse(salt + newPassword);
    const password1 = sha256(saltedPassword).toString();
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    const body = JSON.stringify({
        newPassword: password1,
        token: token,
    });

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
        body: body,
    };

    try {
        const response = await fetch(url, requestOptions);
        if (response.status === 200) {
            return true;
        } else {
            console.error("Failed to update password", response.status);
            return false;
        }
    } catch (error) {
        console.error("Error sending update password request", error);
        return false;
    }
}

export async function submitVerificationToken(token: string) {
    const url = `${apiUrl}user/auth/verify-mail/${token}`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    const requestOptions = {
        method: 'POST',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);
        return response.ok;
    } catch (error) {
        return false;
    }
}
export async function acceptUserByAdmin(userID: number, token: string): Promise<boolean> {
    const url = `${apiUrl}user/auth/accept-user/${userID}`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);
        if (response.status === 200) {
            return true;
        } else {
            console.error(`Failed to verify user: ${response.statusText}`);
            return false;
        }
    } catch (error) {
        console.error('An error occurred while verifying user', error);
        return false;
    }
}

export async function checkUserVerificationStatus(userID: number, token: string) {
    const url = `${apiUrl}user/auth/accept-user-get/${userID}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        if (!response.ok) {
            throw new Error('Failed to fetch verification status');
        }
        const data = await response.json();
        return data.isVerified;
    } catch (error) {
        console.error("Error checking user verification status:", error);
        return false;
    }
}

export async function transferAmount(senderId: number, receiverId: number, walletId: number, amount: number, token: string): Promise<boolean> {
    const url = `${apiUrl}wallet/transfer`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${token}`,
    });

    const body = JSON.stringify({
        senderId,
        receiverId,
        walletId,
        amount,
    });

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
        body: body,
    };

    try {
        const response = await fetch(url, requestOptions);
        if (response.status === 200) {
            return true;
        } else {
            console.error(`Failed to transfer amount: ${response.statusText}`);
            return false;
        }
    } catch (error) {
        console.error('An error occurred while transferring amount', error);
        return false;
    }
}

export async function fetchAssetHistory(assetId: number, token: string): Promise<AssetHistory[] | null> {
    const url = `${apiUrl}asset/history/${assetId}`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${token}`,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);
        if (response.status === 200) {
            const data: AssetHistory[] = await response.json();
            const assetHistoryInstances = await Promise.all(
                data.map(item => AssetHistory.fromJson(item))
            );
            return assetHistoryInstances;
        } else {
            console.error(`Failed to fetch asset history: ${response.statusText}`);
            return null;
        }
    } catch (error) {
        console.error('An error occurred while fetching asset history', error);
        return null;
    }
}

