type RightJSON = {
    ID: number,
    parent: number,
    assetID: number,
    holderID: number,
    isKey: boolean,
    r_type: number
}

export enum r_types {
    READ = 1 << 0,
    WRITE = 1 << 1,
    RENT = 1 << 2,
    OWN = 1 << 3
}

export function rTypeToString(type: r_types): string {
    switch(type) {
        case r_types.READ:
            return "Leserecht";
        case r_types.WRITE:
            return "Schreibrecht";
        case r_types.RENT:
            return "Mietrecht";
        case r_types.OWN:
            return "Besitzrecht";
        default:
            return "undefined";
    }
}

export class Right {
    ID: number;
    parent: number;
    assetID: number;
    holderID: number;
    isKey: boolean;
    r_type: number;

    constructor(
        ID: number,
        parent: number,
        assetID: number,
        holderID: number,
        isKey: boolean,
        r_type: number
    ) {
        this.ID = ID
        this.parent = parent
        this.assetID = assetID
        this.holderID = holderID
        this.isKey = isKey
        this.r_type = r_type
    }

    hasREAD(): boolean {
        return Boolean(this.r_type & r_types.READ);
    }

    setREAD(read: boolean) {
        if (read)
            this.r_type |= r_types.READ;
        else
            this.r_type &= ~r_types.READ;
    }

    hasWRITE(): boolean {
        return Boolean(this.r_type & r_types.WRITE);
    }

    setWRITE(write: boolean) {
        if (write)
            this.r_type |= r_types.WRITE;
        else
            this.r_type &= ~r_types.WRITE;
    }

    hasRENT(): boolean {
        return Boolean(this.r_type & r_types.RENT);
    }

    setRENT(rent: boolean) {
        if (rent)
            this.r_type |= r_types.RENT;
        else
            this.r_type &= ~r_types.RENT;
    }

    hasOWN(): boolean {
        return Boolean(this.r_type & r_types.OWN);
    }

    setOWN(own: boolean) {
        if (own)
            this.r_type |= r_types.OWN;
        else
            this.r_type &= ~r_types.OWN;
    }

    setRtype(read: boolean, write: boolean, rent: boolean, own: boolean) {
        this.setREAD(read);
        this.setWRITE(write);
        this.setRENT(rent);
        this.setOWN(own);
    }

    canEdit(): boolean {
        return this.hasWRITE() || this.hasRENT();
    }

    toJson(): Record<string, any> {
        return {
            'id': this.ID,
            'parent': this.parent,
            'asset_ID': this.assetID,
            'holder_ID': this.holderID,
            'is_key': this.isKey,
            'r_type': this.r_type,
        }
    }

    static fromJson(json: Record<string, any>): Right {
        return new Right(
            json['id'] || "",
            json['parent'] || (json['parent'] == 0 ? 0 : -1),
            json['asset_ID'] || "",
            json['holder_ID'] || "",
            json['is_key'] || "",
            json['r_type'] || ""
        )
    }

    static fromMap(json: Record<string, any>): Right {
        return new Right(
            json.get('id') || "",
            json.get('parent') || "",
            json.get('asset_ID') || "",
            json.get('holder_ID') || "",
            json.get('is_key') || "",
            json.get('r_type') || ""
        );
    }

    static fromList(jsonList: RightJSON[] | null): Right[] {
        if (!jsonList) return [];
        return jsonList.map(json => Right.fromJson(json));
    }
}