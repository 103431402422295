import { AssetDataEntry, AssetType } from "./AssetType";

type AssetJSON = {
    aid: number;
    oid: number;
    type: number;
    created_by: string;
    name: string;
    // photo:
    data: Record<string, Record<string, any>>;
    created_at: string;
    chain_id: string;
}

export class Asset {
    aid: number;
    oid: number;
    type: number;
    created_by: number;
    name: string;
    // photo:
    data: AssetDataEntry[];
    created_at: string;
    chain_id: string;

    constructor(
        aid: number = -1,
        oid: number = -1,
        type: number = -1,
        created_by: number = -1,
        name: string = "",
        data: AssetDataEntry[] = [],
        created_at: string = "",
        chain_id: string = ""
    ) {

        this.aid = aid;
        this.oid = oid;
        this.type = type;
        this.created_by = created_by;
        this.name = name;
        this.data = data;
        this.created_at = created_at;
        this.chain_id = chain_id;
    }

    // TODO [RL] update
    toAssetView(): AssetView {
        return new AssetView(
            this.aid,
            this.type,
            this.name,
        )
    }

    static from(a: Asset | AssetView): Asset {
        if (a instanceof Asset) {
            return new Asset(
                a.aid,
                a.oid,
                a.type,
                a.created_by,
                a.name,
                JSON.parse(JSON.stringify(a.data)), // deep copy
                a.created_at,
                a.chain_id
            );
        }
        return new Asset(
            a.aid,
            -1,
            a.type,
            -1,
            a.name
        );
    }

    toJson(): Record<string, any> {
        return {
            'aid': this.aid,
            'oid': this.oid,
            'type': this.type,
            'created_by': this.created_by,
            'name': this.name,
            'data': AssetDataEntry.listToJson(this.data),
            'created_at': this.created_at,
            'chain_id': this.chain_id,

        }
    }

    static fromJson(json: Record<string, any>): Asset {
        return new Asset(
            json['aid'] || -1,
            json['oid'] || -1,
            json['type'] || -1,
            json['created_by'] || -1,
            json['name'] || "",
            (json['data'] && AssetType.parseStructure(json['data'])) || [],
            json['created_at'] || "",
            json['chain_id'] || "",
        )
    }

    static fromMap(json: Record<string, any>): Asset {
        return new Asset(
            json.get('aid') || -1,
            json.get('oid') || -1,
            json.get('type') || -1,
            json.get('created_by') || -1,
            json.get('name') || "",
            (json.get('data') && AssetType.parseStructure(json.get('data'))) || [],
            json.get('created_at') || "",
            json.get('chain_id') || "",
        );
    }

    static fromList(jsonList: AssetJSON[] | null): Asset[] {
        if (!jsonList) return [];
        return jsonList.map(json => Asset.fromJson(json));
    }

    setEntryData(entry: string, newData: string) {
        const dataEntryIdx  = this.data.findIndex(d => d.name === entry)
        if (dataEntryIdx === -1) {
            return
        }
        this.data[dataEntryIdx].data = newData;
    }
}

export class AssetView {
    aid: number;
    type: number;
    name: string;
    // photo:

    constructor(
        aid: number = -1,
        type: number = -1,
        name: string = "",
    ) {

        this.aid = aid;
        this.type = type;
        this.name = name;
    }

    updateValues(a: AssetView): any {
        this.aid = a.aid;
        this.type = a.type;
        this.name = a.name;
    }

    toJson(): Record<string, any> {
        return {
            'aid': this.aid,
            'type': this.type,
            'name': this.name,
        }
    }

    static fromJson(json: Record<string, any>): AssetView {
        return new AssetView(
            json['aid'] || -1,
            json['type'] || -1,
            json['name'] || "",
        )
    }

    static fromMap(json: Record<string, any>): AssetView {
        return new AssetView(
            json.get('aid') || -1,
            json.get('type') || -1,
            json.get('name') || "",
        );
    }
}