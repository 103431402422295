type AssetTypeJSON = {
    id: number;
    category: number;
    name: string;
    description: string;
    structureJSON: Record<string, any[]>;
    instantiable: boolean;
}

export enum AssetDataEntryType {
    DIRECT = 1,
    DOCID = 2,
    LOG = 3,
    RENTABLE = 4,
}

export enum AssetDataEntryTypeString {
    DIRECT = "Text",
    DOCID = "Dokument",
    LOG = "DatenLog",
    RENTABLE = "Mietbar",
}

export function getAssetDataEntryTypeString(type: AssetDataEntryType): typeof AssetDataEntryTypeString[keyof typeof AssetDataEntryTypeString] {
    switch (type) {
        case AssetDataEntryType.DIRECT:
            return AssetDataEntryTypeString.DIRECT
        case AssetDataEntryType.DOCID:
            return AssetDataEntryTypeString.DOCID
        case AssetDataEntryType.LOG:
            return AssetDataEntryTypeString.LOG
        case AssetDataEntryType.RENTABLE:
            return AssetDataEntryTypeString.RENTABLE
    }
}

export function getAssetDataEntryTypeFromString(type: AssetDataEntryTypeString): typeof AssetDataEntryType[keyof typeof AssetDataEntryType] {
    switch (type) {
        case AssetDataEntryTypeString.DIRECT:
            return AssetDataEntryType.DIRECT
        case AssetDataEntryTypeString.DOCID:
            return AssetDataEntryType.DOCID
        case AssetDataEntryTypeString.LOG:
            return AssetDataEntryType.LOG
        case AssetDataEntryTypeString.RENTABLE:
            return AssetDataEntryType.RENTABLE
    }
}

export class AssetDataEntry {
    name: string;
    accessLevel: number; // Right
    dataType: AssetDataEntryType;
    data: string;

    constructor(name: string, accessLevel: number, dataType: number, data: string) {
        this.name = name;
        this.accessLevel = accessLevel;
        this.dataType = dataType;
        this.data = data;
    }

    getEntry(): Record<string, any> {
        return {
            "accessLevel": this.accessLevel,
            "dataType": this.dataType,
            "data": this.data
        }
    }

    static fromJson(name: string, json: Record<string, any>): AssetDataEntry {
        return new AssetDataEntry(
            name,
            json['accessLevel'] || -1,
            json['dataType'] || -1,
            json['data'] || "",
        )
    }

    static toJson(dataEntry: AssetDataEntry): Record<string, any> {
        return {
            [dataEntry.name]: {
                "accessLevel": dataEntry.accessLevel,
                "dataType": dataEntry.dataType,
                "data": dataEntry.data
            }
        }
    }

    static listToJson(dataEntries: AssetDataEntry[]): Record<string, Record<string, any>> {
        return dataEntries.reduce((pre, val) => ({
            ...pre, [val.name]: { "accessLevel": val.accessLevel, "dataType": val.dataType, "data": val.data }
        }), {} as Record<string, Record<string, any>>);
    }
}

export class AssetType {
    id: number;
    name: string;
    category: number
    description: string;
    structure: AssetDataEntry[];
    instantiable: boolean;

    constructor(
        id: number = -1,
        name: string = "",
        category: number = -1,
        description: string = "",
        structure: AssetDataEntry[] = [],
        instantiable: boolean = false) {
        if (typeof instantiable !== "boolean") {
            let instantiableStr = String(instantiable);
            instantiable = instantiableStr === "1" ? true : false;
        }

        this.id = id;
        this.name = name;
        this.category = category;
        this.description = description;
        this.structure = structure
        this.instantiable = instantiable;
    }

    static parseStructure(structureJSON: Record<string, Record<string, any>>): AssetDataEntry[] {
        return Object.keys(structureJSON).map(name => AssetDataEntry.fromJson(name, structureJSON[name]))
    }


    toJson(): Record<string, any> {
        return {
            'id': this.id,
            'name': this.name,
            'category': this.category,
            'description': this.description,
            'structure': AssetDataEntry.listToJson(this.structure),
            'instantiable': this.instantiable ? 1 : 0,
        }
    }

    static fromJson(json: Record<string, any>): AssetType {
        return new AssetType(
            json['id'] || "",
            json['name'] || "",
            json['category'] || -1,
            json['description'] || '',
            (json['structure'] && this.parseStructure(json['structure'])) || [],
            json['instantiable'] || false,
        )
    }

    static fromMap(json: Record<string, any>): AssetType {
        return new AssetType(
            json.get('id') || "",
            json.get('name') || "",
            json.get('category') || -1,
            json.get('description') || "",
            (json.get('structure') && this.parseStructure(json.get('structure'))) || [],
            json.get('instantiable') || false,
        );
    }

    static fromList(jsonList: AssetTypeJSON[] | null): AssetType[] {
        if (!jsonList) return [];
        return jsonList.map(json => AssetType.fromJson(json));
    }

    static from(a: AssetType): AssetType {
        return new AssetType(
            a.id,
            a.name,
            a.category,
            a.description,
            a.structure,
            a.instantiable)
    }
}
