import React, { useContext, useEffect, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import {fetchAssetHistory, getEmailOrName} from "../utils/RequestApi";
import { AuthContext } from "../context/AuthTokenContext";
import { AssetHistory } from '../types/AssetHistory';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';

interface AssetHistoryListProps {
    assetId: number;
    onClose: () => void;
}
interface GroupedHistory {
    [transUid: string]: AssetHistory[];
}
export const AssetHistoryList: React.FC<AssetHistoryListProps> = ({ assetId, onClose }) => {
    const [history, setHistory] = useState<GroupedHistory>({});
    const { token } = useContext(AuthContext);
    const [expandedTransUid, setExpandedTransUid] = useState<string | null>(null);

    const loadHistoryAndUserDetails = async () => {
        if (!token) return;
        const historyResponse = await fetchAssetHistory(assetId, token);
        if (historyResponse) {
            const historyDataWithDetails = [];
            for (const entry of historyResponse) {
                const senderDetails = await getEmailOrName(entry.senderId, token);
                const receiverDetails = await getEmailOrName(entry.receiverId, token);

                const senderEmail = senderDetails ? senderDetails : "Unknown";
                const receiverEmail = receiverDetails ? receiverDetails : "Unknown";

                const assetHistoryEntry = new AssetHistory(
                    entry.id,
                    entry.assetId,
                    entry.eventType,
                    entry.eventDescription,
                    entry.amount,
                    entry.amountAfterEvent,
                    entry.senderId,
                    entry.receiverId,
                    entry.transactionId,
                    entry.createdAt,
                    entry.transUid,
                    senderEmail,
                    receiverEmail
                );
                historyDataWithDetails.push(assetHistoryEntry);
            }
            setHistory(groupHistoryByTransUid(historyDataWithDetails));
        }
    };

    const groupHistoryByTransUid = (history: any[]) => {
        return history.reduce((acc, entry) => {
            if (!acc[entry.transUid]) {
                acc[entry.transUid] = [];
            }

            acc[entry.transUid].push(entry);
            return acc;
        }, {});
    };


    useEffect(() => {
        loadHistoryAndUserDetails();
    }, [assetId, token]);


    return (
        <div className="history-tile">
            <div className="asset-history-header">
                <FiArrowLeft className="icon back-icon" onClick={onClose}/>
                <h2>Wallet History</h2>
            </div>
            <div className="asset-history-list">
                <table>
                    <tbody>
                    {Object.keys(history).map((transUid, index) => {
                        const isExpanded = expandedTransUid === transUid;
                        const firstEntry = history[transUid][0];
                        return (
                            <React.Fragment key={transUid}>
                                <tr>
                                    <td colSpan={5} onClick={() => setExpandedTransUid(isExpanded ? null : transUid)}>
                                        <button className={`expand-btn ${isExpanded ? 'collapse' : 'expand'}`}>
                                            {isExpanded ? <MdExpandLess /> : <MdExpandMore />}
                                        </button>
                                        <span>{new Date(firstEntry.createdAt).toLocaleString("de-DE")}</span>
                                        <span> from: {firstEntry.senderEmail} </span>
                                        <span> to: {firstEntry.receiverEmail}</span>
                                        <span style={{ color: firstEntry.amount >= 0 ? 'positive-amount' : 'negative-amount' }}>
                                            {` / Amount: ${firstEntry.amount.toFixed(2)} Coins`}
                                        </span>
                                        <span> / Balance after Transaction: {firstEntry.amountAfterEvent.toFixed(2)} Coins </span>

                                    </td>
                                </tr>
                                {isExpanded && (
                                    <>
                                        <tr>
                                            <th>Date</th>
                                            <th>Event Type</th>
                                            <th>Description</th>
                                            <th></th>
                                            <th>Transaction ID</th>
                                        </tr>
                                        {history[transUid].map((entry, subIndex) => (
                                            <tr key={`${transUid}-${subIndex}`}>
                                                <td>{new Date(entry.createdAt).toLocaleString("de-DE")}</td>
                                                <td>{entry.eventType}</td>
                                                <td>{entry.eventDescription}</td>
                                                {subIndex === 1 ? (
                                                    <td className={entry.amount >= 0 ? "positive-amount" : "negative-amount"}>
                                                        {`${entry.amount.toFixed(2)}`} Coins
                                                    </td>
                                                ) : null} {}
                                                {subIndex === 0 ? (
                                                    <td className={entry.amountAfterEvent >= 0 ? "positive-amount" : "negative-amount"}>
                                                        {`${entry.amountAfterEvent.toFixed(2)}`} Coins
                                                    </td>
                                                ) : null} {}
                                                {subIndex === 2 ? (
                                                    <td className={firstEntry.amountAfterEvent - entry.amount >= 0 ? "positive-amount" : "negative-amount"}>
                                                        {`${(firstEntry.amountAfterEvent - entry.amount).toFixed(2)}`} Coins
                                                    </td>
                                                ) : null} {}
                                                <td>{entry.transactionId}</td>
                                            </tr>
                                        ))}

                                    </>
                                )}
                            </React.Fragment>
                        );
                    })}

                    </tbody>
                </table>
            </div>
        </div>
    );

};

export default AssetHistoryList;
