import React, { Fragment, useContext, useEffect, useState } from "react";
import { AuthContext } from '../context/AuthTokenContext';
import { AssetDataEntry, AssetDataEntryType, AssetDataEntryTypeString, AssetType, getAssetDataEntryTypeFromString, getAssetDataEntryTypeString } from '../types/AssetType';
import { FiArrowLeft, FiEdit } from 'react-icons/fi';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, } from "@mui/material";
import { ControlledCheckbox } from "./RightsTable";
import { addAssetType, getAssetCategory, updateAssetType } from "../utils/RequestApi";
import { rTypeToString, r_types } from "../types/Right";
import { AssetCategory } from "../types/AssetCategory";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface NewParamWindowProps {
    onAddParam: (pType: AssetDataEntryType, pName: string, canBeEditedBy: r_types, logColumns: string[]) => void;
    rentableTaken: () => boolean;
}

const NewParamWindow: React.FC<NewParamWindowProps> = (props: { rentableTaken: () => boolean, onAddParam: (pType: AssetDataEntryType, pName: string, canBeEditedBy: r_types, logColumns: string[]) => void }) => {
    const { onAddParam, rentableTaken } = props
    const [parameterType, setParameterType] = useState(AssetDataEntryTypeString.DIRECT);
    const [parameterName, setParameterName] = useState("");
    const [canBeEditedBy, setCanBeEditedBy] = useState(r_types.OWN);
    const [logColumns, setLogColumns] = useState<string[]>(["Datum-Eingetragen", ""]);

    useEffect(() => {
    }, [])

    const handlePTypeChange = (e: SelectChangeEvent) => {
        let newType = AssetDataEntryTypeString.DIRECT;
        switch (e.target.value) {
            case "Text":
                newType = AssetDataEntryTypeString.DIRECT
                break;
            case "Dokument":
                newType = AssetDataEntryTypeString.DOCID
                break;
            case "DatenLog":
                newType = AssetDataEntryTypeString.LOG
                break;
            case "Mietbar":
                newType = AssetDataEntryTypeString.RENTABLE
                break;
        }
        setParameterType(newType);
    };

    const handlePNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setParameterName(e.target.value)
    }

    const handleEditByChange = (e: SelectChangeEvent) => {
        let editableBy = r_types.OWN;
        switch (Number(e.target.value)) {
            case r_types.OWN:
                editableBy = r_types.OWN
                break;
            case r_types.WRITE:
                editableBy = r_types.WRITE
                break;
        }
        setCanBeEditedBy(editableBy)
    }

    const handleNumLogColsChange = (e: SelectChangeEvent) => {
        const newLength = Number(e.target.value)
        if (newLength == logColumns.length) {
            return
        } else if (newLength > logColumns.length) {
            const newElements = Array(newLength - logColumns.length).map(n => "")
            setLogColumns([...logColumns, ...newElements]);
        } else {
            setLogColumns(logColumns.slice(0, newLength))
        }
    }

    const addNewParam = () => {
        const pType = getAssetDataEntryTypeFromString(parameterType)
        onAddParam(pType, parameterName, canBeEditedBy, logColumns)
    }

    const isParamTypeEditable = () => {
        return [AssetDataEntryTypeString.DIRECT,
        AssetDataEntryTypeString.DOCID,
        AssetDataEntryTypeString.LOG].find(type => type == parameterType);
    }

    const isParamTypeNameable = () => {
        return [AssetDataEntryTypeString.DIRECT,
        AssetDataEntryTypeString.DOCID,
        AssetDataEntryTypeString.LOG].find(type => type == parameterType);;
    }

    const getColEntries = () => {
        return (
            logColumns.map((colName, idx) => {
                if (idx == 0) { return <></> }
                return (
                    <tr>
                        <td>Spalten Name {idx + 1}</td>
                        <td>
                            <div className="input-textfield">
                                <TextField
                                    margin="none"
                                    size="small"
                                    value={logColumns[idx]}
                                    onChange={e => {
                                        const newArray = [...logColumns.slice(0, idx), e.target.value, ...logColumns.slice(idx + 1)];
                                        setLogColumns(newArray);
                                    }}
                                />
                            </div>
                        </td>
                    </tr>
                )
            })
        )
    }

    return (
        <Box className="new-asset-type-param-frame">
            <table>
                <tbody>
                    <tr>
                        <td>Neuer Eigenschafts Typ</td>
                        <td>
                            <FormControl>
                                <InputLabel>Eigenschafts Typ</InputLabel>
                                <Select
                                    className="select-mid-height"
                                    value={parameterType}
                                    label="Eigenschafts Typ"
                                    onChange={handlePTypeChange}
                                >
                                    {Object.values(AssetDataEntryTypeString).map(pType => {
                                        if (pType == AssetDataEntryTypeString.RENTABLE && rentableTaken()) {
                                            return <></>
                                        }
                                        return (<MenuItem value={pType}>{pType}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </td>
                    </tr>
                    {isParamTypeNameable() &&
                        <tr>
                            <td>Eigenschaft Name</td>
                            <td>
                                <div className="input-textfield">
                                    <TextField
                                        margin="none"
                                        size="small"
                                        value={parameterName}
                                        onChange={e => handlePNameChange(e)}
                                    />
                                </div>
                            </td>
                        </tr>
                    }
                    {isParamTypeEditable() &&
                        <tr className="asset-type-param-table-wider">
                            <td>Kann editiert werden von</td>
                            <td>
                                <div className="input-textfield">
                                    <FormControl fullWidth>
                                        <InputLabel>Editierbar von</InputLabel>
                                        <Select
                                            className="select-mid-height"
                                            value={String(canBeEditedBy)}
                                            label="Editierbar von"
                                            onChange={handleEditByChange}
                                        >
                                            <MenuItem value={r_types.OWN}>Nur Besitzer</MenuItem>
                                            <MenuItem value={r_types.WRITE}>User mit Schreibrecht</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </td>
                        </tr>
                    }
                    {parameterType == AssetDataEntryTypeString.LOG && (
                        <>
                            <tr>
                                <td colSpan={2}>
                                    <div className="divider-asset-type"></div>
                                </td>
                            </tr>
                            <tr>
                                <td>Anzahl an Log Spalten</td>
                                <td>
                                    <FormControl>
                                        <InputLabel>Spaltenzahl</InputLabel>
                                        <Select
                                            className="select-mid-height"
                                            value={String(logColumns.length)}
                                            label="Anzahl Spalten"
                                            onChange={handleNumLogColsChange}
                                        >
                                            {[2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => {
                                                return <MenuItem value={n}>{n}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </td>
                            </tr>
                        </>
                    )}
                    {parameterType == AssetDataEntryTypeString.LOG && <tr>
                        <td>Spalten Name 1</td>
                        <td>
                            <div className="input-textfield">
                                <TextField
                                    margin="none"
                                    size="small"
                                    value={logColumns[0]}
                                    disabled={true}
                                />
                            </div>
                        </td>
                    </tr>
                    }
                    {parameterType == AssetDataEntryTypeString.LOG && getColEntries()}
                </tbody>
            </table>
            <button onClick={addNewParam}>Hinzufügen</button>
        </Box>
    );
}

function DynamicDataTable(props: { assetType: AssetType, setAssetType: (at: AssetType) => void, isEditable: boolean, isCreatingNew: boolean, setNewParamWindowOpen: (b: boolean) => void }) {
    const { token } = useContext(AuthContext);
    const { assetType, setAssetType, isEditable, isCreatingNew, setNewParamWindowOpen } = props;
    const [dataEntries, setDataEntries] = useState({} as Record<string, AssetDataEntry>);
    const [isSetUp, setIsSetUp] = useState(false);
    const [categoryName, setCategoryName] = useState("");


    const fetchCategoryName = async () => {
        if (!token) return;
        getAssetCategory(assetType.category, token).then(assetCategory => {
            if (!assetCategory) {
                setCategoryName("Lade Fehler");
            } else {
                setCategoryName(assetCategory.name);
            }
        })
    }

    const setupDataEntries = async () => {
        const newDataEntries = assetType.structure.reduce((prev, val) => ({ ...prev, [val.name]: val }), {} as typeof dataEntries);
        setDataEntries(newDataEntries);
    }

    useEffect(() => {
        setupDataEntries();
        fetchCategoryName()
        setIsSetUp(true);
    }, [])

    useEffect(() => {
        setupDataEntries()
    }, [assetType])

    const setDataEntriesInAssetType = (entries: Record<string, AssetDataEntry>) => {
        const newStructure = Object.values(entries);
        const newAssetType = AssetType.from(assetType);
        newAssetType.structure = newStructure;
        setAssetType(newAssetType);
        setDataEntries(entries);
    }

    const deleteEntry = (name: string) => {
        const newDataEntries = { ...dataEntries };
        delete newDataEntries[name];
        setDataEntriesInAssetType(newDataEntries);
    }

    const updateDataEntry = (name: string, newValue: any, accessLevel: number | undefined = undefined, dataType: AssetDataEntryType | undefined = undefined) => {
        // leave values as is
        const oldEntry = dataEntries[name];
        if (!accessLevel) {
            accessLevel = oldEntry?.accessLevel || r_types.RENT;
        }
        if (!dataType) {
            dataType = oldEntry?.dataType || AssetDataEntryType.DIRECT;
        }

        const newDataEntries = { ...dataEntries };
        newDataEntries[name] = new AssetDataEntry(name, accessLevel, dataType, newValue);
        setDataEntriesInAssetType(newDataEntries);
    }

    const updateDataEntryGeneral = (name: string, newValue: any) => {
        const newAssetType = AssetType.from(assetType);
        (newAssetType as any)[name] = newValue;
        setAssetType(newAssetType);
    }

    const getInputFieldGeneral = (entry: string, disabled: boolean = false, multiline: boolean = false) => {
        const value = (assetType as any)[entry];

        if (entry == 'instantiable') {
            return <ControlledCheckbox
                getChecked={() => assetType.instantiable}
                setChecked={b => assetType.instantiable = b}
                disabled={disabled}></ControlledCheckbox>;

        } else if (typeof value === 'string' || typeof value === 'number') {
            let defaultValue = value;
            if (entry === "id" && value === -1) {
                defaultValue = "auto"
            }
            if (entry === "category") {
                return (
                    <div className="input-textfield">
                        <TextField
                            margin="none"
                            size="small"
                            value={categoryName}
                            disabled={disabled}
                        />
                    </div>
                )
            }

            if (multiline) {
                return (
                    <div className="input-textfield">
                        <TextField
                            margin="none"
                            size="small"
                            defaultValue={defaultValue}
                            multiline
                            onChange={e => updateDataEntryGeneral(entry, e.target.value)}
                            disabled={disabled}
                        />
                    </div>
                )
            }
            return (
                <div className="input-textfield">
                    <TextField
                        margin="none"
                        size="small"
                        defaultValue={defaultValue}
                        onChange={e => updateDataEntryGeneral(entry, e.target.value)}
                        disabled={disabled}
                    />
                </div>
            )
        }

        return <div>undefined</div>
    }

    const getInputTextField = (entry: string, disabled: boolean = false, multiline: boolean = false) => {
        const value = dataEntries[entry].data as string

        if (multiline) {
            return (
                <div className="input-textfield">
                    <TextField
                        size="small"
                        defaultValue={value}
                        multiline
                        onChange={e => updateDataEntry(entry, e.target.value)}
                        disabled={disabled}
                    />
                </div>
            )
        }
        return (
            <div className="input-textfield">
                <TextField
                    size="small"
                    defaultValue={value}
                    onChange={e => updateDataEntry(entry, e.target.value)}
                    disabled={disabled}
                />
            </div>
        )
    }


    const isNewDataEntryTaken = (entryName: string) => {
        // check general names
        if ((assetType as any)[entryName.toLowerCase()] !== undefined && entryName.toLowerCase() !== "structure") return true;
        if (dataEntries[entryName] !== undefined) return true;
        return false;
    }

    const getMakeNewDataEntry = () => {
        const openNewParamWindow = () => {
            setNewParamWindowOpen(true);
        }

        return (
            <tr>
                <td colSpan={2}>
                    <button className="small-button" onClick={openNewParamWindow}>Neue Eigenschaft</button>
                </td>
            </tr>
        )
    }

    const getAccessLevelEntry = (entry: string) => {
        const accessLevel = dataEntries[entry].accessLevel

        return (
            <TextField
                margin="none"
                size="small"
                label="Editierbar mit"
                value={rTypeToString(accessLevel)}
                disabled={true}
                style={{ width: 120 }}
                InputLabelProps={{ shrink: true }}
            />
        )
    }

    const getDataTypeEntry = (entry: string) => {
        const dataType = dataEntries[entry].dataType
        const value = getAssetDataEntryTypeString(dataType)

        return (
            <TextField
                margin="none"
                size="small"
                label="Attribut Typ"
                value={value}
                disabled={true}
                style={{ width: 110 }}
            />
        )
    }

    const getMainComponent = () => {
        return (
            <Fragment>
                <table className="asset-type-param-table">
                    <tbody>
                        <tr>
                            <td>ID</td>
                            <td colSpan={2}>{getInputFieldGeneral('id', true)}</td>
                        </tr>
                        <tr>
                            <td>Kategorie</td>
                            <td colSpan={2}>{getInputFieldGeneral('category', true)}</td>
                        </tr>
                        <tr>
                            <td>Kann erstellt werden</td>
                            <td colSpan={2}>{getInputFieldGeneral('instantiable', !isEditable)}</td>
                        </tr>
                        <tr>
                            <td>Beschreibung</td>
                            <td colSpan={2}>{getInputFieldGeneral('description', !isEditable, true)}</td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <div className="divider-asset-type"></div>
                            </td>
                        </tr>
                        {Object.keys(dataEntries).map(entry => (
                            <tr className="asset-type-param-table-wide">
                                <td>{entry == "rentable" ? "Element mieten" : entry}</td>
                                <td>{getAccessLevelEntry(entry)}</td>
                                <td>{getDataTypeEntry(entry)}</td>
                                <td>{isCreatingNew && <DeleteIcon onClick={() => deleteEntry(entry)} />}</td>
                            </tr>
                        ))}
                        {(isEditable && isCreatingNew) && getMakeNewDataEntry()}
                    </tbody>
                </table>
            </Fragment>)
    }

    return (
        <>
            {isSetUp && getMainComponent()}
        </>
    )
}

interface CreateAssetTypeProps {
    onClose: () => void;
    assetType: AssetType | null
    isEditable: boolean;
    createNew: boolean;
    category: AssetCategory;
    onSave: () => void;
}

export const CreateAssetType: React.FC<CreateAssetTypeProps> = ({ onClose, assetType, isEditable, createNew, onSave }) => {
    const [isCreatingNew, setIsCreatingNew] = useState(createNew)
    const [isEditing, setIsEditing] = useState(isCreatingNew);
    const [currentAssetType, setCurrentAssetType] = useState(assetType || new AssetType());
    const { token } = useContext(AuthContext);
    const [displayAssetType, setDisplayAssetType] = useState(assetType || new AssetType());

    const [newParamWindowOpen, setNewParamWindowOpen] = useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const newAssetType = AssetType.from(displayAssetType);
        (newAssetType as any)[field] = event.target.value;
        setDisplayAssetType(newAssetType);
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = () => {
        if (!token) return;
        setNewParamWindowOpen(false);
        const saveNewAssetType = async () => {
            await addAssetType(displayAssetType, token).then(success => {
                if (!success) {
                    alert("Konnte Element Typ nicht speichern")
                    return;
                }
                setCurrentAssetType(AssetType.from(displayAssetType));
                setIsEditing(false);
                setIsCreatingNew(false);
            })
        };

        const updateAssetTypeWrap = async () => {
            await updateAssetType(displayAssetType, token).then(success => {
                if (!success) {
                    alert("Konnte Element Typ nicht updaten");
                    return;
                }
                setCurrentAssetType(AssetType.from(displayAssetType));
                setIsEditing(false);
            })
        };

        if (isCreatingNew) {
            saveNewAssetType().then(onSave);
        } else {
            updateAssetTypeWrap().then(onSave);
        }
    };

    const handleAddNewParam = (pType: AssetDataEntryType, pName: string, canBeEditedBy: r_types, logParams: string[]) => {
        const newAssetType = AssetType.from(displayAssetType);

        // empty entry check
        if (pName == "" && pType != AssetDataEntryType.RENTABLE) {
            alert("Eigenschafts name fehlt")
            return
        } else if (pType == AssetDataEntryType.LOG && logParams.find(e => e == "")) {
            alert("Ein oder mehrere Spalten Namen fehlen!")
            return
        }

        const reservedPNames = ["name", "rentable"]
        if (reservedPNames.find(e => e == pName)) {
            alert(`"${pName}" ist ein reservierter Name. Bitte wähle eine alternative Bezeichnung`)
            return
        }

        // check if pName is taken
        if (newAssetType.structure.find(entry => entry.name == pName)) {
            alert(`Eigenschafts Name "${pName}" ist bereits vergeben. Bitte verwende einen neuen Eigenschafts Namen.`);
            return;
        }

        let defaultData = "";
        if (pType == AssetDataEntryType.LOG) {
            // clean entry names from separator
            defaultData = logParams.map(colName => colName.replaceAll(";", "")).join(";")
        } else if (pType == AssetDataEntryType.RENTABLE) {
            pName = "rentable";
            canBeEditedBy = r_types.READ;
            defaultData = "Datum-Eingetragen;user;from;to"
        }

        const newDataEntry = new AssetDataEntry(pName, canBeEditedBy, pType, defaultData);
        // sort entries after AssetDataEntryType
        const newStructure = [...newAssetType.structure, newDataEntry].sort((a, b) => {
            if (a.dataType > b.dataType) {
                return 1;
            }
            if (b.dataType > a.dataType) {
                return -1;
            }
            return 0;
        })
        newAssetType.structure = newStructure;

        setDisplayAssetType(newAssetType);
        setCurrentAssetType(newAssetType);
        setNewParamWindowOpen(false);
    };

    const rentableIsTaken = () => {
        return Boolean(currentAssetType.structure.find(dataEntry => dataEntry.dataType == AssetDataEntryType.RENTABLE))
    }

    return (
        <>
            {newParamWindowOpen && <NewParamWindow rentableTaken={rentableIsTaken} onAddParam={handleAddNewParam} />}
            <Box className="asset-type-info">
                <div className="header">
                    {!isEditing && <FiArrowLeft className="icon back-icon" onClick={onClose} />}
                    {isEditing && (isCreatingNew ?
                        <FiArrowLeft className="icon back-icon-transparent" />
                        :
                        <FiArrowLeft className="icon back-icon" onClick={() => setIsEditing(false)} />)
                    }

                    {isEditing && (
                        <div className="tooltip">
                            <input
                                type="text"
                                value={displayAssetType.name}
                                onChange={e => handleInputChange(e, 'name')}
                                placeholder="Element Typ Name"
                            />
                        </div>)}
                    {!isEditing && <h2>{currentAssetType.name}</h2>}

                    {(!isEditable || isEditing) && <FiEdit className="icon edit-icon-transparent" />}
                    {(isEditable && !isEditing) && <FiEdit className="icon edit-icon" onClick={handleEdit} />}
                </div>
                <div className="divider-asset-type"></div>
                <>
                    {isEditing ? (
                        <DynamicDataTable assetType={displayAssetType} setAssetType={setDisplayAssetType} isEditable={isEditing} isCreatingNew={isCreatingNew} setNewParamWindowOpen={setNewParamWindowOpen}></DynamicDataTable>

                    ) : displayAssetType && (
                        <DynamicDataTable assetType={currentAssetType} setAssetType={setDisplayAssetType} isEditable={isEditing} isCreatingNew={isCreatingNew} setNewParamWindowOpen={setNewParamWindowOpen}></DynamicDataTable>
                    )
                    }
                    {isEditing && (
                        <button onClick={handleSave}>Speichern</button>
                    )}
                </>
            </Box>
        </>
    )
}