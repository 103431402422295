
import React from 'react';
import { jwtDecode } from "jwt-decode";


export interface AuthContextProps {
    token: string | null;
    setToken: (token: string | null) => void;
}

export const AuthContext = React.createContext<AuthContextProps>({
    token: null,
    setToken: () => {}
});


export function setTimerToAlertTokenExpiration(token: string | null) {
    if (!token) return
    const decodedToken = jwtDecode(token)
    if (!decodedToken) return
    const expiration =  decodedToken.exp
    if (!expiration) return

    const now = Math.floor(Date.now() / 1000)
    const delay =  expiration - now
    setTimeout(() => alert("Deine Sitzung ist abgelaufen, bitte logge dich erneut ein"), delay * 1000, )
}