import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Document } from '../types/Document';
import { Asset } from '../types/Asset'
import { FiArrowLeft, FiEdit } from 'react-icons/fi';
import { AuthContext } from "../context/AuthTokenContext";
import { bookDocumentTransaction, bookEncryptedDocumentTransaction, createAsset, fetchAssetType, fetchAssetTypes, getAsset, getDocumentInfo, getEmailOrName, updateAsset } from "../utils/RequestApi";
import { Box, Button, FormControl, Link, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { AssetDataEntry, AssetDataEntryType, AssetType } from '../types/AssetType';
import { UserContext } from '../context/UserContext';
import ClearIcon from '@mui/icons-material/Clear';
import { IoMdOpen } from "react-icons/io";
import { ShowPDFFullscreen } from './DocumentTile';
import { DataGrid, GridRowsProp, GridColDef, GridToolbar, GridToolbarContainer, GridToolbarExport, deDE } from '@mui/x-data-grid';
import { RentRow, RentableFrame } from './RentAssetView'
import UserList from './UserList';
import AssetHistoryList from "./AssetHistoryList";


function AddLogDataFrame(props: { header: string, onSave: (row: string) => void }) {
    const { header, onSave } = props;
    const [logColumns, setLogColumns] = useState<string[]>([]);
    const [values, setValues] = useState<string[]>([])

    const setup = () => {
        const newColumns = header.split(";")
        setLogColumns(newColumns);
        setValues(Array(newColumns.length).map(v => ""))
    }

    useEffect(() => {
        setup();
    }, []);

    const getCols = () => {
        return logColumns.map((colName, idx) => {
            if (idx == 0) { return <></> }
            return (
                <tr>
                    <td>{colName}</td>
                    <td>
                        <div className="input-textfield">
                            <TextField
                                margin="none"
                                size="small"
                                value={values[idx]}
                                onChange={e => {
                                    const newArray = [...values.slice(0, idx), e.target.value, ...values.slice(idx + 1)];
                                    setValues(newArray);
                                }}
                            />
                        </div>
                    </td>
                </tr>
            )
        })
    }

    return (
        <div className="add-log-data-row-frame"
            onClick={e => { e.stopPropagation() }}>
            <table>
                <tr>
                    <td>{logColumns[0]}</td>
                    <td>
                        <TextField
                            margin="none"
                            size="small"
                            value="auto"
                            disabled={true} />
                    </td>
                </tr>
                {getCols()}
            </table>
            <button
                onClick={() => onSave(values.join(";"))}
            >Speicher neuen Eintrag</button>
        </div>
    )
}

function LogDataFrame(props: { data: string }) {
    const { data } = props;
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [columns, setColumns] = useState<GridColDef[]>([]);

    function getToolBar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport printOptions={{ disableToolbarButton: true }}
                    csvOptions={{ delimiter: ";" }} />
            </GridToolbarContainer>
        );
    }

    const setupTable = () => {
        const lines = data.split("\n");
        const newColumns = lines[0].split(";").map((colName, idx) => {
            return { field: `col${idx + 1}`, headerName: colName, width: 200, cellClassName: 'log-data-grid-cell', }
        })
        const newRows = lines.slice(1).map((line, idx) => {
            return line.split(";").reduce((prev, val, curIdx) => {
                return { ...prev, [`col${curIdx + 1}`]: val }
            }, { id: idx + 1 })
        })

        setColumns(newColumns);
        setRows(newRows);
    }

    useEffect(() => {
        setupTable()
    }, [])


    return (
        <div className="log-data-table"
            onClick={e => { e.stopPropagation() }}>
            <DataGrid className="datagrid" rows={rows} columns={columns} slots={{ toolbar: getToolBar, }} localeText={deDE.components.MuiDataGrid.defaultProps.localeText} />
        </div>
    );
}

function DynamicDataTable(props: {
    asset: Asset,
    setAsset: (at: Asset) => void,
    isEditable: boolean,
    isCreatingNew: boolean,
    typeName: string;
    filesToUpload: Record<string, File>,
    setFilesToUpload: (ftu: Record<string, File>) => void,
    showPdf: (did: string) => void
    onShowLogData: (data: string) => void
    onAddNewLogEntry: (entry: string, header: string) => void
    onRent: () => void
}) {
    const { asset, setAsset, isEditable, isCreatingNew, typeName, filesToUpload, setFilesToUpload, showPdf, onShowLogData, onAddNewLogEntry, onRent } = props;
    const [dataEntries, setDataEntries] = useState<Record<string, AssetDataEntry>>({});
    const { token } = useContext(AuthContext);
    // asset types
    const [selectedType, setSelectedType] = useState("-1");
    const [availAssetTypes, setAvailAssetTypes] = useState<AssetType[]>([]);
    const [isLoadingAssetTypes, setIsLoadingAssetTypes] = useState(false);
    // files
    const [fileNames, setFileNames] = useState<Record<string, string>>({})

    const [ownerName, setOwnerName] = useState("");

    useEffect(() => {
        const loadOwnerName = async () => {
            if (isCreatingNew) {
                setOwnerName("auto");
                return
            }
            if (asset.oid == -1) return;
            if (!token) return;
            getEmailOrName(asset.oid, token).then(name => {
                if (name) {
                    setOwnerName(name);
                }
            })
        }

        loadOwnerName();
    }, []);

    const loadAssetTypes = () => {
        if (!token) return;
        if (isLoadingAssetTypes) return;
        setIsLoadingAssetTypes(true);
        fetchAssetTypes(token, true).then(assetTypes => {
            setAvailAssetTypes(assetTypes);
            setIsLoadingAssetTypes(false);
        })
    }

    const setupDataEntries = (assetForSetup: Asset) => {
        const newDataEntries = assetForSetup.data.reduce((prev, val) => ({ ...prev, [val.name]: val }), {} as typeof dataEntries);
        setDataEntries(newDataEntries);
    }

    const setupFileNames = (asset: Asset) => {
        if (!token) return
        const newFileNamesProm = asset.data.map(async dataEntry => {
            if (dataEntry.dataType != AssetDataEntryType.DOCID) return null
            if (!dataEntry.data) return null


            const did = Number(dataEntry.data)
            const doc = await getDocumentInfo(did, token, asset.aid);
            if (!doc) {
                return { entry: dataEntry.name, fname: "loading error" }
            } else {
                return { entry: dataEntry.name, fname: doc.name }
            }
        })
        Promise.all(newFileNamesProm).then(arr => {
            const newFileNames = arr.reduce((pre, val) => {
                if (!val) return pre;
                else {
                    return { ...pre, [val.entry]: val.fname }
                }
            }, {} as Record<string, string>)
            setFileNames(newFileNames)
        })
    }

    useEffect(() => {
        if (isCreatingNew) {
            loadAssetTypes();
        }
    }, [])

    useEffect(() => {
        setupDataEntries(asset);
        setupFileNames(asset);
    }, [asset])

    const setDataEntriesInAsset = (entries: Record<string, AssetDataEntry>) => {
        const newData = Object.values(entries);
        const newAsset = Asset.from(asset);
        newAsset.data = newData;
        setAsset(newAsset);
        setDataEntries(entries);
    }

    const getInputFieldGeneral = (entry: string, disabled: boolean = false, multiline: boolean = false) => {
        const value = (asset as any)[entry];

        if (typeof value === 'string' || typeof value === 'number') {
            let defaultValue = value;
            const autoEntries = ["aid", "oid", "created_by", "created_at", "chain_id"];
            if (isCreatingNew && autoEntries.find(e => e == entry)) {
                defaultValue = "auto"
            }

            if (multiline) {
                return (
                    <div className="input-textfield">
                        <TextField
                            fullWidth
                            size="small"
                            defaultValue={defaultValue}
                            multiline
                            onChange={e => updateDataEntryGeneral(entry, e.target.value)}
                            disabled={disabled}
                        />
                    </div>
                )
            }
            return (
                <div className="input-textfield">
                    <TextField
                        fullWidth
                        size="small"
                        defaultValue={defaultValue}
                        onChange={e => updateDataEntryGeneral(entry, e.target.value)}
                        disabled={disabled}
                    />
                </div>
            )
        }
    }

    const getInputTextField = (entry: string, disabled: boolean = false, multiline: boolean = false) => {
        const value = dataEntries[entry].data as string

        if (multiline) {
            return (
                <div className="input-textfield">
                    <TextField
                        size="small"
                        defaultValue={value}
                        multiline
                        onChange={e => updateDataEntry(entry, e.target.value)}
                        disabled={disabled}
                    />
                </div>
            )
        }
        return (
            <div className="input-textfield">
                <TextField
                    size="small"
                    defaultValue={value}
                    onChange={e => updateDataEntry(entry, e.target.value)}
                    disabled={disabled}
                />
            </div>
        )
    }

    const updateFileName = (entry: string, filename: string) => {
        const newFileNames = { ...fileNames, [entry]: filename };
        setFileNames(newFileNames);
    }

    const updateDocEntry = (entry: string, file: File) => {
        const newFilesToUpload = { ...filesToUpload, [entry]: file };
        setFilesToUpload(newFilesToUpload);
        updateFileName(entry, file.name)
    }

    const deleteDocEntry = (entry: string) => {
        const newFilesToUpload = { ...filesToUpload }
        delete newFilesToUpload[entry];
        setFilesToUpload(newFilesToUpload);

        const newFileNames = { ...fileNames };
        delete newFileNames[entry]
        setFileNames(newFileNames);

        updateDataEntry(entry, "")
    }

    const getInputDocField = (entry: string, disabled: boolean = false) => {
        const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files?.[0];
            if (!file) return;
            updateDocEntry(entry, file);
        }

        const getUploadButton = () => {
            return (
                <Button variant="contained" component="label"                        >
                    Upload
                    <input type="file" hidden onChange={handleChange} />
                </Button>
            )
        }

        const showPdfClickableIcon = () => {
            const did = dataEntries[entry].data
            return (
                <div>
                    <IoMdOpen onClick={() => showPdf(did)}></IoMdOpen>
                </div>
            )
        }

        return (
            <div className="input-textfield">
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    {isEditable && getUploadButton()}
                    <TextField
                        size="small"
                        value={fileNames[entry] || "no file"}
                        disabled={true}
                    />
                    <Stack>
                        {isEditable && <ClearIcon onClick={() => deleteDocEntry(entry)} />}
                        {fileNames[entry] && showPdfClickableIcon()}
                    </Stack>
                </Box>

            </div>
        )
    }

    const getInputLogField = (entry: string, disabled: boolean) => {
        const onClick = () => {
            const header = dataEntries[entry].data.split("\n")[0]
            onAddNewLogEntry(entry, header)
        }

        return (
            <button
                className="button-new-log-entry"
                disabled={isEditable || isCreatingNew}
                onClick={onClick}
            >Speicher neuen Eintrag</button>
        )
    }

    const getInputDataField = (entry: string, disabled: boolean = false, multiline: boolean = false) => {
        const dataType = dataEntries[entry].dataType;
        if (dataType == AssetDataEntryType.DIRECT) {
            return (
                <>
                    <td>{entry}</td>
                    <td>{getInputTextField(entry, disabled, multiline)}</td>
                </>
            );
        }
        if (dataType == AssetDataEntryType.DOCID) {
            return (
                <>
                    <td>{entry}</td>
                    <td>{getInputDocField(entry, disabled)}</td>
                </>
            );
        }
        if (dataType == AssetDataEntryType.LOG) {
            return (
                <>
                    <td>{entry}</td>
                    <td>
                        <div className='test-horizontal'>
                            {getInputLogField(entry, disabled)}
                            <button
                                className="button-show-log-entries"
                                disabled={isEditable || isCreatingNew}
                                onClick={() => {
                                    const data = dataEntries[entry].data
                                    onShowLogData(data);
                                }}
                            >Zeige Einträge</button>
                        </div>
                    </td>
                </>
            );
        }
        if (dataType == AssetDataEntryType.RENTABLE) {
            return (
                <>
                    <td />
                    <td>
                        <div>
                            <button
                                disabled={isEditable || isCreatingNew}
                                onClick={onRent}
                            >Mieten</button>
                        </div>
                    </td>
                </>
            );
        }
        return <p>unbekannter Datentyp!</p>
    }

    const updateDataEntry = (name: string, newValue: any) => {
        const newDataEntries = { ...dataEntries };
        newDataEntries[name].data = newValue;
        setDataEntriesInAsset(newDataEntries);
    }

    const updateDataEntryGeneral = (name: string, newValue: any) => {
        const newAsset = Asset.from(asset);
        (newAsset as any)[name] = newValue;
        setAsset(newAsset);
    }

    const getDataTableEntries = () => {
        const keys = Object.keys(dataEntries);
        const keysGroupedByTwo = [];
        for (let i = 0; i < keys.length; i += 2) {
            keysGroupedByTwo.push({ one: keys[i], two: i + 1 < keys.length ? keys[i + 1] : undefined })
        }

        return (
            keysGroupedByTwo.map(entries => (
                <tr>
                    {getInputDataField(entries.one, !isEditable)}
                    {entries.two != undefined &&
                        <>{getInputDataField(entries.two, !isEditable)}</>
                    }
                </tr>
            ))
        )
    }

    return (

        <Fragment>
            <table>
                <tbody>
                    <tr>
                        <td>Element ID</td>
                        <td>{getInputFieldGeneral('aid', true)}</td>
                        <td>Element Typ</td>
                        <td>
                            <div className="input-textfield">
                                <TextField
                                    size="small"
                                    value={typeName}
                                    disabled={true}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Erstellt von</td>
                        <td>
                            <div className="input-textfield">
                                <TextField
                                    size="small"
                                    value={ownerName}
                                    disabled={true}
                                />
                            </div>
                        </td>

                        <td>Erstellt am</td>
                        <td>{getInputFieldGeneral('created_at', true)}</td>
                    </tr>
                    <tr>
                        <td>Chain ID</td>
                        <td colSpan={3}>{getInputFieldGeneral('chain_id', true)}</td>
                    </tr>
                    <tr>
                        <td colSpan={4}>
                            <div className="divider-asset-type"></div>
                        </td>
                    </tr>
                    {getDataTableEntries()}
                </tbody>
            </table>
        </Fragment>
    )
}

interface AssetInfoProps {
    onClose: () => void;
    onSave: () => void;
    asset: Asset | null;
    onUpdate: () => void;
    isEditable: boolean;
    createNew: boolean;
    typeNew: number | undefined;
}

export const AssetInfo: React.FC<AssetInfoProps> = ({ onClose, onSave, asset, isEditable, createNew, typeNew, onUpdate }) => {
    const [isEditing, setIsEditing] = useState(createNew);
    const [isCreatingNew, setIsCreatingNew] = useState(createNew)
    const [updatedAsset, setUpdatedAsset] = useState(new Asset());
    const { token } = useContext(AuthContext);
    const { profileView, isAdmin } = useContext(UserContext);
    const [displayAsset, setDisplayAsset] = useState(asset || new Asset());
    const [filesToUpload, setFilesToUpload] = useState<Record<string, File>>({});

    const [showingPdf, setShowingPdf] = useState(false);
    const [currentDidToShow, setCurrentDidToShow] = useState<number>(-1)

    const [showingDataTable, setShowingDataTable] = useState(false);
    const [dataTableData, setDataTableData] = useState("");

    const [showingNewDataLogRow, setShowingNewDataLogRow] = useState(false);
    const [headerOfNewDataLogRow, setHeaderOfNewDataLogRow] = useState("");
    const [currentEntryOfNewLogEntry, setCurrentEntryOfNewLogEntry] = useState("");

    const [typeName, setTypeName] = useState("");
    const [showUserList, setShowUserList] = useState(false);

    const [showingRentableCalendar, setShowingRentableCalendar] = useState(false);
    const [showAssetHistory, setShowAssetHistory] = useState(false);
    const [selectedAssetId, setSelectedAssetId] = useState<number | null>(null);
    const handleShowAssetHistory = (assetId: number) => {
        setSelectedAssetId(assetId);
        setShowAssetHistory(true);
    };
    const showPdf = (did: string) => {
        setCurrentDidToShow(Number(did));
        setShowingPdf(true)
    }
    const onTransferCoins = () => {
        setShowUserList(true);
    };
    const handleCloseUserList = () => {
        setShowUserList(false);
        onUpdate();
        onClose();
    };

    const hidePdf = () => {
        setShowingPdf(false)
    }

    const loadTypeName = (id: number) => {
        if (!token) return;
        fetchAssetType(id, token).then(aType => {
            if (aType) {
                setTypeName(aType.name);
            } else {
                setTypeName("Lade Fehler")
            }
        })
    }

    useEffect(() => {
        // setup updatedAsset
        if (!token) return;
        const newAsset = Asset.from(asset || new Asset());
        if (isCreatingNew && typeNew) {
            newAsset.type = typeNew;
            fetchAssetType(typeNew, token).then(aType => {
                if (aType) {
                    newAsset.data = aType.structure;
                    setUpdatedAsset(newAsset);
                }
            })
            loadTypeName(typeNew);
        } else {
            setUpdatedAsset(newAsset);
            loadTypeName(newAsset.type);
        }
    }, [typeNew])

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = () => {
        if (!token) return;
        if (!profileView) return;
        const assetToSave = Asset.from(updatedAsset);
        assetToSave.oid = profileView.uid;

        if(assetToSave.name === "") {
            return;
        }

        Promise.all(Object.keys(filesToUpload).map(async entry => {
            const file = filesToUpload[entry]
            const fileExtension = (file.name.split('.').pop() ?? '').toLowerCase();
            let res;
            if (fileExtension === 'pdf') {
                res = await bookEncryptedDocumentTransaction(file, file.name, token);
            } else {
                res = await bookDocumentTransaction(file, file.name, token);
            }
            return { entry: entry, result: res }
        })).then(uploads => {
            if (uploads.every(u => u.result.success === true)) {
                // set did in updated asset
                uploads.forEach(u => assetToSave.setEntryData(u.entry, String(u.result.did)))
                return true
            }
            alert("Konnte nicht alle oder einige Dokumnet uploaden. \nBitte checke unter \"Managae Dokumente\" ob manche Dokumente bereits geuploaded wurden bevor du erneut versuchst ein Element zu erstellen")
            return false
        }).then(success => {
            if (success) {
                if(assetToSave.type == 1) {
                    let dataObject:  AssetDataEntry[] = assetToSave.data;

                    Object.entries(dataObject).forEach(([key, value]) => {
                        if (value.data === "") {
                            value.data = "0";
                        }
                    });

                    assetToSave.data = dataObject;
                }
                return createAsset(assetToSave, token) // TODO [RL] update aid from response
            }
            return false
        }).then(success => {
            if (success) {
                alert("Erfolgreich Element erstellt")
                setDisplayAsset(assetToSave);
            } else {
                alert("Konnte Element nicht erstellen")
            }

            setIsCreatingNew(false);
            setIsEditing(false);
        }).finally(() => {
            onSave()
        })
    }

    const handleUpdate = () => {
        if (!token) return;

        Promise.all(Object.keys(filesToUpload).map(async entry => {
            const file = filesToUpload[entry]
            const fileExtension = (file.name.split('.').pop() ?? '').toLowerCase();
            let res;
            if (fileExtension === 'pdf') {
                res = await bookEncryptedDocumentTransaction(file, file.name, token);
            } else {
                res = await bookDocumentTransaction(file, file.name, token);
            }
            return { entry: entry, result: res }
        })).then(uploads => {
            if (uploads.every(u => u.result.success === true)) {
                // set did in updated asset
                uploads.forEach(u => updatedAsset.setEntryData(u.entry, String(u.result.did)))
                return true
            }
            alert("Konnte nicht alle oder einige Dokumnet uploaden. \nBitte checke unter \"Managae Dokumente\" ob manche Dokumente bereits geuploaded wurden bevor du erneut versuchst ein Element zu erstellen")
            return false
        }).then(success => {
            if (!success) return
            if(updatedAsset.name == "") return;
            return updateAsset(updatedAsset, token)
        }).then(success => {
            if (success) {
                alert("Erfolgreich Element geupdatet");
                setDisplayAsset(updatedAsset)
            } else {
                alert("Konnte Element nicht updaten");
            }
        }).catch(error => {
            alert(`Fehler beim Updaten vom Element: ${error}`);
        }).finally(() => {
            setIsEditing(false);
        })
    };

    const handleUpdateLogEntry = (newAsset: Asset) => {
        if (!token) return;

        updateAsset(newAsset, token)
            .then(success => {
                if (success) {
                    setDisplayAsset(newAsset)
                    setUpdatedAsset(newAsset)
                } else {
                    alert("Konnte Element nicht updaten");
                }
            }).then(_ => {
                // reload asset
                const reloadAssets = () => getAsset(newAsset.aid, token).then(reloadedAsset => {
                    if (reloadedAsset) {
                        setUpdatedAsset(reloadedAsset);
                        setDisplayAsset(reloadedAsset);
                    } else {
                        alert(`Konnte geupdatetes Element nicht neu laden. Angezeigte Daten könnten nicht aktuell sein.`);
                    }
                })
                const ms = 100
                setTimeout(reloadAssets, ms) // minimal delay necessary, or else changes are not registered on db
            }).catch(error => {
                alert(`Fehler beim Updaten vom Element: ${error}`);
            }).finally(() => {
                setIsEditing(false);
            })
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const newAsset = Asset.from(updatedAsset);
        (newAsset as any)[field] = event.target.value;
        setUpdatedAsset(newAsset);
    };

    const onShowLogData = (data: string) => {
        setDataTableData(data);
        setShowingDataTable(true);
    }

    const onSaveNewLogEntry = (row: string) => {
        const newAsset = Asset.from(updatedAsset);
        const oldEntry = newAsset.data.find((val) => val.name == currentEntryOfNewLogEntry)
        if (!oldEntry) {
            alert("Skript error. Konnte Daten nicht updaten")
            return
        }
        oldEntry.data = oldEntry.data + `\n${row}`
        newAsset.setEntryData(currentEntryOfNewLogEntry, oldEntry.data)

        handleUpdateLogEntry(newAsset);
        setShowingNewDataLogRow(false);

    }

    const onAddNewLogEntry = (entry: string, header: string) => {
        setShowingNewDataLogRow(true);
        setHeaderOfNewDataLogRow(header);
        setCurrentEntryOfNewLogEntry(entry);
    }

    const onRent = () => {
        setShowingRentableCalendar(true);
    }

    const getRentData = () => {
        const rentData = updatedAsset.data.find(e => e.dataType == AssetDataEntryType.RENTABLE)?.data
        if (rentData) {
            return rentData;
        }
        alert("Keine Informationen über den Mietkalender des Elements vorhanden")
        return ""
    }

    const handleNewRentData = (newEntry: RentRow) => {
        const newAsset = Asset.from(updatedAsset);
        const oldEntry = newAsset.data.find((val) => val.name == "rentable")
        if (!oldEntry) {
            alert("Skript error. Konnte Daten nicht updaten")
            return
        }
        const newDataRow = `${newEntry.entryDate.toString()};${newEntry.userID};${newEntry.from};${newEntry.to}`
        oldEntry.data = oldEntry.data + `\n${newDataRow}`
        newAsset.setEntryData("rentable", oldEntry.data)

        handleUpdateLogEntry(newAsset);
        setShowingRentableCalendar(false);
    }

    return (
        <>
            {showAssetHistory && selectedAssetId && (
                <><div className="strong-overlay">
                <AssetHistoryList
                    assetId={selectedAssetId}
                    onClose={() => setShowAssetHistory(false)}
                />
                </div></>
            )}
            {showUserList && (
                <UserList
                    onClose={handleCloseUserList}
                    showSendButton={false}
                    showTransferButton={true}
                    openedFrom={profileView?.uid || -1}
                    walletId={displayAsset.aid}
                    showUserProfiles={false}
                />
            )}
            {showingPdf &&
                <div className="strong-overlay">
                    <ShowPDFFullscreen did={currentDidToShow} onClose={hidePdf} aid={(asset && asset.aid) || -1} />
                </div>}
            {showingDataTable &&
                <div className="strong-overlay" onClick={() => setShowingDataTable(false)}>
                    <LogDataFrame data={dataTableData} />
                </div>}
            {showingNewDataLogRow && <div className="strong-overlay" onClick={() => setShowingNewDataLogRow(false)}>
                <AddLogDataFrame header={headerOfNewDataLogRow} onSave={onSaveNewLogEntry} />
            </div>}
            {showingRentableCalendar && <div className="strong-overlay" onClick={() => setShowingRentableCalendar(false)}>
                <RentableFrame rentData={getRentData()} onRent={handleNewRentData}/>
            </div>}
            <div className={isCreatingNew ? "asset-info-create-new" : "asset-info"}>
                <div className="header">
                    {!isEditing && <FiArrowLeft className="icon back-icon" onClick={onClose} />}
                    {isEditing && <FiArrowLeft className="icon back-icon" onClick={() => setIsEditing(false)} />}

                    {isEditing && (
                        <div className="tooltip">
                            <input
                                type="text"
                                value={updatedAsset.name}
                                onChange={e => handleInputChange(e, 'name')}
                                placeholder="Element Name"
                            />
                            <span className="tooltip-text">Element Namen eingeben</span>
                        </div>)}
                    {!isEditing && <h2>{displayAsset.name}</h2>}
                    {(!isEditable || isEditing || (displayAsset.type == 1 && (!isAdmin || profileView?.uid !== displayAsset.oid))) && <FiEdit className="icon edit-icon-transparent" />}
                    {(isEditable && !isEditing && (displayAsset.type != 1 || (displayAsset.type == 1 && isAdmin && profileView?.uid == displayAsset.oid))) && <FiEdit className="icon edit-icon" onClick={handleEdit} />}

                </div>
                <div className="divider"></div>
                <>
                    {isEditing ? (
                        isCreatingNew ? (
                            typeNew == 1 ? (
                                <DynamicDataTable asset={updatedAsset} setAsset={setUpdatedAsset} isEditable={false} isCreatingNew={true} typeName={typeName} filesToUpload={filesToUpload} setFilesToUpload={setFilesToUpload} showPdf={showPdf} onShowLogData={onShowLogData} onAddNewLogEntry={onAddNewLogEntry} onRent={onRent} />
                            ) : (
                                <DynamicDataTable asset={updatedAsset} setAsset={setUpdatedAsset} isEditable={true} isCreatingNew={true} typeName={typeName} filesToUpload={filesToUpload} setFilesToUpload={setFilesToUpload} showPdf={showPdf} onShowLogData={onShowLogData} onAddNewLogEntry={onAddNewLogEntry} onRent={onRent} />
                            )
                        ) : (
                            <DynamicDataTable asset={updatedAsset} setAsset={setUpdatedAsset} isEditable={true} isCreatingNew={false} typeName={typeName} filesToUpload={filesToUpload} setFilesToUpload={setFilesToUpload} showPdf={showPdf} onShowLogData={onShowLogData} onAddNewLogEntry={onAddNewLogEntry} onRent={onRent} />
                        )

                    ) : displayAsset ? (
                        (displayAsset.data) ? (
                            <>
                            <DynamicDataTable asset={displayAsset} setAsset={() => { }} isEditable={false} isCreatingNew={false} typeName={typeName} filesToUpload={filesToUpload} setFilesToUpload={setFilesToUpload} showPdf={showPdf} onShowLogData={onShowLogData} onAddNewLogEntry={onAddNewLogEntry} onRent={onRent} />
                                {displayAsset.type === 1 && displayAsset.oid === profileView?.uid && (
                                    <>
                                    <button onClick={onTransferCoins} className="transfer-coins-button">
                                        Transfer Coins
                                    </button>
                                        <button onClick={() => handleShowAssetHistory(displayAsset.aid)} className="view-history-button">
                                            View History
                                        </button>
                                    </>
                                )}
                            </>
                        ) : (
                            <div className="user-info">
                                <p>Keine Information vorhanden.</p>
                            </div>
                        )
                    ) : (
                        <div className="user-info">
                            <p>Keine Information vorhanden.</p>
                        </div>
                    )}


                    {isEditing && !isCreatingNew && (
                        <button onClick={handleUpdate}>Update</button>
                    )}
                    {isEditing && isCreatingNew && (
                        <button onClick={handleSave} >Speichern</button>
                    )}

                </>
            </div>

            </>
    )
}