import React, { useState} from "react";
import {fetchPasswordReset} from "../utils/RequestApi";

import tum_logo from '../assets/tum_logo.png';
import catena_logo from '../assets/Logo_white.svg';
import perper_logo from "../assets/perper_logo.png";

type PasswordResetProps = {
    navigateTo: (page: 'main' | 'login' | 'register' | 'home' | 'forgotPassword') => void;
};

const PasswordReset: React.FC<PasswordResetProps> = ({ navigateTo }) => {
    const [email, setEmail] = useState<string>('');

    const handlePasswordReset= async () => {
        if (email === '') {
            alert(" Bitte gebe eine E-Mail ein.");
            return;
        }
        try {
            const passwordReset = await fetchPasswordReset(email.toLowerCase());
            if (passwordReset) {
                alert("Passwort zurücksetzen erfolgreich! Bitte überprüfe deine E-Mails.");
                navigateTo('login');
            } else {
                alert("Fehler beim zurücksetzen! Hast du die richtige E-Mail angegeben?");
            }
        } catch (error) {
            alert("Fehler beim zurücksetzen! Hast du die richtige E-Mail angegeben?");
        }
    };
    return (
        <div className="login-container">
            <div className="login-logo-container">
                <div className="login-logo-row">
                    <img src={tum_logo} alt="tum" className="login-logo-big" />
                </div>
                <div className="login-logo-row">
                    <span className="digital-contracts">Project name</span>
                </div>
                <div className="login-logo-row">
                    <span className="powered-by">powered by</span>
                </div>
                <div className="login-logo-row">
                    <img src={catena_logo} alt="catena" className="login-logo" />
                </div>
            </div>
            <div className="login-logo-row">
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="login-logo-row">
                <button className="login-button" onClick={handlePasswordReset}>Link anfordern</button>
            </div>
            <div className="register-link-container">
                <span className="register-link" onClick={() => navigateTo('register')}>Noch keinen Account? Registriere dich hier.</span>
            </div>
        </div>
    );
}



export default PasswordReset;
