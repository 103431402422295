export class Profile {
    givenName: string;
    surname: string;
    postleitzahl?: string;
    wohnort?: string;
    strasse?: string;
    hausnummer?: string;
    telefonnummer?: string;
    rolle?: string;

    constructor(
        givenName: string,
        surname: string,
        postleitzahl?: string,
        wohnort?: string,
        strasse?: string,
        hausnummer?: string,
        telefonnummer?: string,
        rolle?: string
    ) {
        this.givenName = givenName;
        this.surname = surname;
        this.postleitzahl = postleitzahl;
        this.wohnort = wohnort;
        this.strasse = strasse;
        this.hausnummer = hausnummer;
        this.telefonnummer = telefonnummer;
        this.rolle = rolle;
    }

    toJson(): Record<string, any> {
        return {
            'name': this.givenName,
            'surname': this.surname,
            'plz': this.postleitzahl,
            'city': this.wohnort,
            'strasse': this.strasse,
            'hausnummer': this.hausnummer,
            'phone': this.telefonnummer,
            'role': this.rolle,
        };
    }

    static fromJson(json: Record<string, any>): Profile {
        return new Profile(
            json['name'] || "",
            json['surname'] || "",
            json['plz'] || "",
            json['city'] || "",
            json['strasse'] || "",
            json['hausnummer'] || "",
            json['phone'] || "",
            json['role'] || ""
        );
    }

    static fromMap(json: Record<string, any>): Profile {
        return new Profile(
            json.get('name') || "",
            json.get('surname') || "",
            json.get('plz') || "",
            json.get('city') || "",
            json.get('strasse') || "",
            json.get('hausnummer') || "",
            json.get('phone') || "",
            json.get('role') || ""
        );
    }
}


// TODO [RL] this class is duplicate to Users class, replace its occurrences with User
export class ProfileView {
    uid: number;
    email: string;

    constructor(
        uid: number,
        email: string
    ) {
        this.uid = uid;
        this.email = email;
    }

    toJson(): Record<string, any> {
        return {
            'uid': this.uid,
            'email': this.email,
        };
    }

    static fromJson(json: Record<string, any>): ProfileView {
        return new ProfileView(
            json['uid'] || -1,
            json['email'] || "",
        );
    }

    static fromMap(json: Record<string, any>): ProfileView {
        return new ProfileView(
            json.get('uid') || -1,
            json.get('email') || "",

        );
    }
}