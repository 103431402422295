import React, { useState, FC, ReactNode, useMemo } from 'react';
import { ProfileView } from '../types/Profile';
import { UserContext } from '../context/UserContext';

interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
    const [profileView, setProfileView] = useState<ProfileView | null>(null);
    const [userName, setUserName] = useState<string | null>(null);
    const [isAdmin, setIsAdmin] = useState<boolean>(false)

    const contextValue = useMemo(() => {
        return { profileView, setProfileView, userName, setUserName, isAdmin, setIsAdmin };
    }, [profileView, userName]);

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};
