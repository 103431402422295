import { Document } from '../types/Document';
import {
    addDocument,
    deleteDocument,
    getDecryptedDocument,
    getDocument,
    getDocumentInfo,
    getEmailOrName,
    getUseChainID
} from "../utils/RequestApi";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../context/AuthTokenContext";
import * as pdfjsLib from 'pdfjs-dist';
import { UserList } from './UserList';
import { FaTrash } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { getLogoSrc } from "../utils/HelperFunc"
import { Document as Document_React, Page, pdfjs } from 'react-pdf';
import { DataGrid, GridColDef, GridRowsProp, GridToolbarContainer, GridToolbarExport, deDE } from '@mui/x-data-grid';

export const ShowPDFFullscreen: React.FC<{ did: number; onClose: () => void; aid: number }> = ({ did, onClose, aid }) => {
    const { token } = useContext(AuthContext);
    const [numPages, setNumPages] = useState<number>(0);
    const canvasRefs = useRef<HTMLCanvasElement[]>([]);
    const [downloading, setDownloading] = useState(false);
    const [showUserList, setShowUserList] = useState(false);
    const [document, setDocument] = useState<Document>(new Document({ did: -1, uid: -1, name: "", password: "", chainId: "" }))
    const [fileType, setFileType] = useState("");

    useEffect(() => {
        loadPdf();
    }, [])

    const loadPdf = async () => {
        if (!token) return;
        setDownloading(true);
        pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        window.document.body.classList.add('downloading');
        try {
            const document = await getDocumentInfo(did, token, aid);
            if (document) {
                setDocument(document)
                const documentBytes = await getDocument(did, token, aid);
                let fileType = document.name.split('.').pop() || ""
                setFileType(fileType)
                if (fileType === 'pdf') {
                    const loadingTask = pdfjsLib.getDocument({ data: documentBytes, password: document.password });
                    const pdf = await loadingTask.promise;
                    setNumPages(pdf.numPages);
                    for (let i = 1; i <= pdf.numPages; i++) {
                        const page = await pdf.getPage(i);
                        const scale = 1;
                        const viewport = page.getViewport({ scale });
                        const canvas = canvasRefs.current[i - 1];
                        const context = canvas.getContext('2d');
                        if (!context) {
                            console.error("Unable to get canvas context");
                            continue;
                        }
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;
                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport
                        };
                        const renderTask = page.render(renderContext);
                        await renderTask.promise;
                    }

                }
            }
        } catch (error) {
            console.error("Error displaying the document:", error);
        }
        setDownloading(false);
        window.document.body.classList.remove('downloading');
    };

    const toggleUserList = (show: boolean) => {
        setShowUserList(show);
        if (show) {
            window.document.body.style.overflow = 'hidden';
        } else {
            window.document.body.style.overflow = 'auto';
        }
    };

    const handleDownload = async () => {
        if (!token) return;
        setDownloading(true);
        window.document.body.classList.add('downloading');
        try {
            let documentBytes;
            if (fileType === 'pdf') {
                documentBytes = await getDecryptedDocument(did, token, aid);
            } else {
                documentBytes = await getDocument(did, token, aid);
            }
            const blob = new Blob([documentBytes], { type: `application/${fileType}` });
            const link = window.document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = document.name;
            link.click();

        } catch (error) {
            console.error("Error downloading the file:", error);
        }
        setDownloading(false);
        window.document.body.classList.remove('downloading');
    };


    return (
        <div className="pdf-fullscreen">
            <div className="button-container">
                {downloading ? (
                    <div></div>
                ) : (
                    <button className="close-button" onClick={onClose}>    <AiOutlineClose />
                    </button>
                )}
                {downloading ? (
                    <button className="download-button" disabled>
                        <div className="spinner"></div>
                    </button>
                ) : (
                    <button className="download-button" onClick={handleDownload}>Download</button>
                )}
                <button className="user-list-toggle" onClick={() => toggleUserList(true)}>Send to user</button>
                {showUserList && (
                    <div className="user-overlay">
                        <UserList showUserProfiles={false} onClose={() => toggleUserList(false)} showSendButton={true}
                            onSendClick={async (uid) => {
                                if (!token) return;
                                const success = await addDocument(did, uid, token);
                                if (success) {
                                    alert('Dokument erfolgreich hinzugefügt');
                                } else {
                                    alert('Dokument wird bereits mit diesem User geteilt');
                                }
                            }} />
                    </div>
                )}
            </div>
            {Array.from({ length: numPages }).map((_, index) => (
                <canvas key={index} className="pdf-page-canvas"
                    ref={el => canvasRefs.current[index] = el as HTMLCanvasElement}></canvas>
            ))}
        </div>
    )
}

export function CsvFrame(props: { data: string, separator?: string | undefined}) {
    const { data, separator } = props;
    const [sepUsed] = useState(separator || ";")
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [columns, setColumns] = useState<GridColDef[]>([]);

    function getToolBar() {
        return <GridToolbarContainer/>
    }

    const setupTable = () => {
        const lines = data.split("\n");
        const newColumns = lines[0].split(sepUsed).map((colName, idx) => {
            return { field: `col${idx + 1}`, headerName: colName, width: 200, cellClassName: 'log-data-grid-cell', }
        })
        const newRows = lines.slice(1).map((line, idx) => {
            return line.split(sepUsed).reduce((prev, val, curIdx) => {
                return { ...prev, [`col${curIdx + 1}`]: val }
            }, { id: idx + 1 })
        })

        setColumns(newColumns);
        setRows(newRows);
    }

    useEffect(() => {
        setupTable()
    }, [])


    return (
        <div className="csv-display"
            onClick={e => { e.stopPropagation() }}>
            <DataGrid className="datagrid" rows={rows} columns={columns} slots={{ toolbar: getToolBar, }} localeText={deDE.components.MuiDataGrid.defaultProps.localeText} />
        </div>
    );
}

export const DocumentTile: React.FC<{
    document: Document;
    onDelete: (did: number) => void;
}> = ({ document, onDelete }) => {
    const { token } = useContext(AuthContext);
    const [showPDFFullscreen, setShowPDFFullscreen] = useState(false);
    const fileType = document.name.split('.').pop();
    const [numPages, setNumPages] = useState<number>(0);
    const canvasRefs = useRef<HTMLCanvasElement[]>([]);
    const [pdfDocument, setPdfDocument] = useState<pdfjsLib.PDFDocumentProxy | null>(null);
    const [downloading, setDownloading] = useState(false);
    const [showUserList, setShowUserList] = useState(false);
    const [showDownloadMessage, setShowDownloadMessage] = useState(false);
    const [fileExtension, setFileExtension] = useState('');
    const [owner, setOwner] = useState<string | null>(null);
    const [chainID, setChainID] = useState<string | null>(null);
    const [showInfo, setShowInfo] = useState(false);
    const [documentBytes, setDocumentBytes] = useState<Uint8Array | undefined>();

    let logoSrc = getLogoSrc(fileType || "");
    const fetchOwner = async () => {
        if (!token) return;
        const result = await getEmailOrName(document.uid, token);
        if (result) {
            setOwner(result);
        }
    };
    fetchOwner();
    const toggleUserList = (show: boolean) => {
        setShowUserList(show);
        if (show) {
            window.document.body.style.overflow = 'hidden';
        } else {
            window.document.body.style.overflow = 'auto';
        }
    };

    const handleInfoHover = async () => {
        if (!token) return;
        const fetchedChainID = await getUseChainID(document.uid, token);
        setChainID(fetchedChainID);
        setShowInfo(true);
    };
    const handleInfoLeave = () => {
        setShowInfo(false);
    };

    const handleDownload = async () => {
        if (!token) return;
        setDownloading(true);
        window.document.body.classList.add('downloading');
        try {
            let documentBytes;
            if (fileType === 'pdf') {
                documentBytes = await getDecryptedDocument(document.did, token);
            } else {
                documentBytes = await getDocument(document.did, token);
            }
            const blob = new Blob([documentBytes], { type: `application/${fileType}` });
            const link = window.document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = document.name;
            link.click();

        } catch (error) {
            console.error("Error downloading the file:", error);
        }
        setDownloading(false);
        window.document.body.classList.remove('downloading');
    };

    const handleClick = async () => {
        if (!token) return;
        setDownloading(true);
        if (showInfo) setShowInfo(false);
        window.document.body.classList.add('downloading');
        pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        try {
            const documentBytes = await getDocument(document.did, token);
            if (fileType === 'pdf') {
                const loadingTask = pdfjsLib.getDocument({ data: documentBytes, password: document.password });
                setShowPDFFullscreen(true);
                const pdf = await loadingTask.promise;
                setNumPages(pdf.numPages);
                setPdfDocument(pdf);
                for (let i = 1; i <= pdf.numPages; i++) {
                    const page = await pdf.getPage(i);
                    const scale = 1;
                    const viewport = page.getViewport({ scale });
                    const canvas = canvasRefs.current[i - 1];
                    const context = canvas.getContext('2d');
                    if (!context) {
                        console.error("Unable to get canvas context");
                        continue;
                    }
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };
                    const renderTask = page.render(renderContext);
                    await renderTask.promise;
                }
            } else {
                if (fileType) {
                    setFileExtension(fileType);
                }
                setShowDownloadMessage(true);
            }
            setDocumentBytes(documentBytes);
        } catch (error) {
            console.error("Error displaying the document:", error);
        }
        setDownloading(false);
        window.document.body.classList.remove('downloading');
    };

    const getDocumentDisplay = () => {
        switch (fileType?.toLowerCase()) {
            case "pdf":
                return (
                    Array.from({ length: numPages }).map((_, index) => (
                        <canvas key={index} className="pdf-page-canvas"
                            ref={el => canvasRefs.current[index] = el as HTMLCanvasElement} />))
                )
            case "jpg":
            case "jpeg":
            case "gif":
            case "bmp":
            case "png":
                if (documentBytes) {
                    const blob = new Blob([documentBytes], { type: `application/${fileType}` });
                    return (
                        <div className="image-container">
                            <img className='document-img' src={URL.createObjectURL(blob)} />
                        </div>
                    )
                }
                break;
            case "txt":
                if (documentBytes) {
                    const txtString = String.fromCharCode(...Array.from(documentBytes))
                    return (
                        <div className='image-container'>
                            <div className='txt-display'>
                                {txtString.split("\n").map(line => <div>{line}</div>)}
                            </div>
                        </div>
                    )
                }
                break;
            case 'csv':
                if (documentBytes) {
                    const numberArr = Array.from(documentBytes).slice(3) // remove first three entries --> seem to be remnants from file encoding
                    let txtString = String.fromCharCode(...numberArr)
                    const sep = ";"
                    // add empty header to string
                    const firstRow = txtString.split("\n")[0]
                    const numCols = firstRow.length - firstRow.replaceAll(sep, "").length
                    txtString = sep.repeat(numCols).concat("\n", txtString).trim()
                    
                    return (
                        <div className='image-container'>
                            <CsvFrame data={txtString}/>
                        </div>
                    )
                }
                break;
            default:
                return (
                    <div className="download-message">
                        Dateien vom Typ .{fileExtension} Können nicht angezeigt werden. Bitte downloaden!
                    </div>
                )
        }
    }

    return (
        <div>
            <div className="document-tile">
                <img src={logoSrc} alt={`${fileType} logo`} className="document-logo" />
                <div className="document-info">
                    <h5>{document.name}</h5>
                    <p>Besitzer: {owner}</p>
                    <p>TA ID: {document.chainId}</p>
                </div>
                <div className="overlay" onClick={handleClick}>
                    {downloading ? (
                        <div className="spinner"></div>
                    ) : (
                        <>
                            <div
                                className="info-icon"
                                onMouseEnter={handleInfoHover}
                                onMouseLeave={handleInfoLeave}
                            >
                                <IoIosInformationCircleOutline />
                            </div>
                            <span>Öffnen</span>
                            <button
                                className="delete-button"
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    if (!token) return;
                                    const confirmDelete = window.confirm('Are you sure you want to delete this document?');
                                    if (confirmDelete) {
                                        const success = await deleteDocument(document, token);
                                        if (success) {
                                            alert('Dokument erfolgreich gelöscht');
                                            onDelete(document.did);
                                        } else {
                                            alert('Konnte Dokument nicht löschen');
                                        }
                                    }
                                }}
                            >
                                <FaTrash />
                            </button>
                        </>
                    )}
                </div>
            </div>
            {showInfo && <div className="info-tooltip">User ChainID: {chainID?.substring(1, chainID?.length - 2)}</div>}
            {showDownloadMessage && (
                <div className="pdf-fullscreen">
                    <div className="button-container">
                        {downloading ? (
                            <div></div>
                        ) : (
                            <button className="close-button" onClick={() => setShowDownloadMessage(false)}>    <AiOutlineClose />
                            </button>
                        )}
                        {downloading ? (
                            <button className="download-button" disabled>
                                <div className="spinner"></div>
                            </button>
                        ) : (
                            <button className="download-button" onClick={handleDownload}>Download</button>
                        )}
                        <button className="user-list-toggle" onClick={() => toggleUserList(true)}>Send to user</button>
                        {showUserList && (
                            <div className="user-overlay">
                                <UserList showUserProfiles={false} onClose={() => toggleUserList(false)} showSendButton={true}
                                    onSendClick={async (uid) => {
                                        if (!token) return;
                                        const success = await addDocument(document.did, uid, token);
                                        if (success && success != "") {
                                            alert('Dokument erfolgreich hinzugefügt mit TA ID: ' + success);
                                        } else {
                                            alert('Dokument wird beireits mit diesem User geteilt');
                                        }
                                    }} />
                            </div>
                        )}
                    </div>
                    {getDocumentDisplay()}
                </div>
            )}
            {showPDFFullscreen && (
                <div className="pdf-fullscreen">
                    <div className="button-container">
                        {downloading ? (
                            <div></div>
                        ) : (
                            <button className="close-button" onClick={() => setShowPDFFullscreen(false)}>    <AiOutlineClose />
                            </button>
                        )}
                        {downloading ? (
                            <button className="download-button" disabled>
                                <div className="spinner"></div>
                            </button>
                        ) : (
                            <button className="download-button" onClick={handleDownload}>Download</button>
                        )}
                        <button className="user-list-toggle" onClick={() => toggleUserList(true)}>Send to user</button>
                        {showUserList && (
                            <div className="user-overlay">
                                <UserList showUserProfiles={false} onClose={() => toggleUserList(false)} showSendButton={true}
                                    onSendClick={async (uid) => {
                                        if (!token) return;
                                        const success = await addDocument(document.did, uid, token);
                                        if (success) {
                                            alert('Dokument erfolgreich hinzugefügt');
                                        } else {
                                            alert('Dokument wird beireits mit diesem User geteilt');
                                        }
                                    }} />
                            </div>
                        )}
                    </div>
                    {getDocumentDisplay()}
                </div>
            )}
        </div>
    );
};
